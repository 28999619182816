export const drawEventChartItem = (canvas, ctx, ld, yOffset, boundariesRef, chart_item) => {
    let snapshot = ld.active_snapshot ;
    const bd = boundariesRef.current ;

    // console.log("Drawing Event Item at ", yOffset, " item=", chart_item) ;
    ctx.save() ;

    if (!chart_item.show) {
        return yOffset;
    }

    // draw a faint horizontal line across the canvas
    ctx.save() ;
    ctx.beginPath();
    ctx.moveTo(bd.left_pad, yOffset);
    ctx.lineTo(bd.width - bd.right_pad, yOffset);
    ctx.setLineDash([3, 5]);
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
    ctx.stroke();
    ctx.restore() ;

    if (chart_item.shade_back) {
        ctx.save() ;
        ctx.fillStyle = chart_item.bg_color || 'rgba(214,248,214, 0.6)';
        ctx.fillRect(bd.left_pad, yOffset, bd.width - (bd.right_pad + bd.left_pad), bd.tl_item_h);
        ctx.restore() ;
    }

    if (!chart_item.available) {
        ctx.save() ;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'red';
        ctx.font = '14px Arial';
        ctx.fillText(`This item is not available for this lift`, bd.left_pad + 5, yOffset + bd.tl_item_h / 2);
        ctx.restore() ;
        return yOffset + bd.tl_item_h ;
    }

    if (chart_item.instances.length === 0) {
        return yOffset + bd.tl_item_h ;
    }

    let isLookup = chart_item.type === 'LOOKUP' ;
    for (let event of chart_item.instances) {
        const startX = bd.left_pad + event.ts.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;
        const endX = bd.left_pad + event.te.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;
        let eventY = yOffset + bd.tl_top_pad;

        let w = endX - startX ;
        const jwidth = Math.max(24, w) ;

        if (isLookup) {
            if (!event.show) {
                continue ;
            }

            if (chart_item.allow_multi_height) {
                let bh = bd.tl_bar_h
                ctx.fillStyle = event.color;

                switch (event.display_section) {
                    case 'top' :
                        eventY = yOffset  ;
                        ctx.fillRect(startX, eventY, endX - startX, bh);
                        break;
                    case 'bottom' :
                        eventY = yOffset + (bd.tl_bar_h * 2);
                        ctx.fillRect(startX, eventY, endX - startX, bh);
                        break;
                    case 'mid' :
                        eventY = yOffset + (bd.tl_bar_h);
                        ctx.fillRect(startX, eventY, endX - startX, bh);
                        break;
                    case 'topbot' :
                        eventY = yOffset ;
                        bh = bd.tl_item_h ;
                        ctx.fillRect(startX, eventY, endX - startX, bh);
                        eventY += bd.tl_bar_h + 2 ;
                        break;
                    case 'all' :
                        eventY = yOffset ;
                        bh = bd.tl_item_h ;
                        ctx.fillRect(startX, eventY, endX - startX, bh);
                        eventY += bd.tl_bar_h + 2 ;
                        break;
                }

            }
            else {
                if (jwidth <= 24) {
                    ctx.beginPath();
                    ctx.moveTo(startX, eventY + bd.tl_bar_h / 2); // Point of the triangle
                    ctx.lineTo(startX + 20, eventY); // Top corner
                    ctx.lineTo(startX + 20, eventY + bd.tl_bar_h); // Bottom corner
                    ctx.closePath();
                    ctx.fillStyle = event.color || chart_item.color || 'rgba(0, 0, 0, 0.3)';
                    ctx.fill();
                }
                else {
                    ctx.fillStyle = event.color || chart_item.color || 'rgba(0, 0, 0, 0.5)';
                    ctx.fillRect(startX, eventY, endX - startX, bd.tl_bar_h);
                }
            }

            // Draw the event label at the startX + 10
            ctx.save();
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            ctx.fillText(event.label, startX + 5, eventY + bd.tl_bar_h / 2);
            ctx.restore();
        }
        else {
            if (jwidth <= 24) {
                ctx.beginPath();
                ctx.moveTo(startX, eventY + bd.tl_bar_h / 2); // Point of the triangle
                ctx.lineTo(startX + 20, eventY); // Top corner
                ctx.lineTo(startX + 20, eventY + bd.tl_bar_h); // Bottom corner
                ctx.closePath();
                ctx.fillStyle = event.color || chart_item.color || 'rgba(0, 0, 0, 0.5)';
                ctx.fill();
            }
            else {
                ctx.fillStyle = event.color || chart_item.color || 'rgba(0, 0, 0, 0.5)';
                ctx.fillRect(startX, eventY, endX - startX, bd.tl_bar_h);
            }
        }
    }

    ctx.restore();

    return yOffset + bd.tl_item_h ;
}
