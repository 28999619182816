import {drawDownArrow, drawLeftArrow, drawUpArrow} from "./drawSymbolHelpers";

export const drawJourneys = (canvas, ctx, ld, boundariesRef) => {
    let snapshot = ld.active_snapshot;
    let journey = snapshot.journeys;
    if (!journey) {
        return;
    }
    const bd = boundariesRef.current;
    const offsetY = bd.journey_start_y; // Offset from the top

    ctx.save();
    if (!journey.show) {
        return;
    }
    const eventY = offsetY;

    if (journey.show) {
        ctx.save() ;
        ctx.fillStyle = journey.bg_color || 'rgba(235,250,235,0.6)';
        ctx.fillRect(bd.left_pad, offsetY, bd.width - (bd.right_pad + bd.left_pad), bd.journey_h);
        ctx.restore() ;
    }

    if (journey.instances.length === 0) {
        return;
    }

    for (let event of journey.instances) {
        const startX = bd.left_pad + event.ts.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;
        const endX = bd.left_pad + event.te.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;

        let w = endX - startX;
        const jwidth = Math.max(24, w);

        // console.log("Drawing Journey Item at x=", startX, " y=", eventY, " item=", event.direction);
        if (event.direction === 'up') {
            ctx.fillStyle = journey.up_color;
            drawUpArrow(ctx, startX, eventY, jwidth, bd.journey_h, event.occupied);
        } else if (event.direction === 'down') {
            ctx.fillStyle = journey.down_color;
            drawDownArrow(ctx, startX, eventY, jwidth, bd.journey_h, event.occupied);
        } else if (event.direction === 'stationary') {
            ctx.fillStyle = journey.st_color;
            drawLeftArrow(ctx, startX, eventY, jwidth, bd.journey_h, event.occupied);
        }

        // draw a vertical line down from the top of the marker display_section to the bottom of the canvas
        ctx.save() ;
        ctx.beginPath();
        ctx.moveTo(startX, eventY);
        ctx.lineTo(startX, bd.height - bd.xax_lbl_h);
        ctx.setLineDash([5, 5]); // Set the line dash pattern
        ctx.strokeStyle = journey.color;
        ctx.stroke();

        // draw a line down from the end of the marker display_section to the bottom of the canvas
        ctx.beginPath();
        ctx.moveTo(endX, eventY);
        ctx.lineTo(endX, bd.height - bd.xax_lbl_h);
        ctx.setLineDash([5, 5]); // Set the line dash pattern
        ctx.strokeStyle = journey.color;
        ctx.stroke();
        ctx.restore() ;
    }
    ctx.restore();
}
