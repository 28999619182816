/*
The following code is in C and needs converting to JavaScript



 */

const bytes_to_hex = (bytes) => {
    if (!bytes || bytes.length === 0) {
        return "";
    }

    let result = "";
    const hex = "0123456789abcdef";
    for (let i = 0; i < bytes.length; i++) {
        const byte = bytes[i];
        // hash[i * 2] = hex[(byte & 0xF0) >> 4];
        // hash[i * 2 + 1] = hex[byte & 0x0F];
        result += hex[(byte & 0xF0) >> 4];
        result += hex[byte & 0x0F];
    }
    return result;
}

const generate_uuid = (uuid) => {
    const hex = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid[i] = '-';
        } else if (i === 14) {
            const random = Math.floor(Math.random() * 4);
            uuid[i] = random + 'a';
        } else {
            const random = Math.floor(Math.random() * 10);
            uuid[i] = random + '0';
        }
    }
}





const hex_to_string = (hex, str, size) => {
    if (!hex) {
        return false;
    }
    for (let i = 0, j = 0; i < size * 2; i += 2, j++) {
        str[i] = (hex[j] & (0xf0)) >> 4;
        if (str[i] <= 9) {
            str[i] = str[i] + '0';
        } else {
            str[i] = str[i] - 10 + 'a';
        }

        str[i + 1] = (hex[j] & (0x0f));
        if (str[i + 1] <= 9) {
            str[i + 1] = str[i + 1] + '0';
        } else {
            str[i + 1] = str[i + 1] - 10 + 'a';
        }
    }
    str[size * 2] = '\0';
    return true;
}


const string_to_hex = (str, hex, size) => {
    if (!str || str.length !== size * 2) {
        return false;
    }
    for (let i = 0, j = 0; j < size; i += 2, j++) {
        hex[j] = 0;
        if (str[i] >= '0' && str[i] <= '9') {
            hex[j] = (str[i] - '0') << 4;
        } else if (str[i] >= 'a' && str[i] <= 'f') {
            hex[j] = (str[i] - 'a' + 10) << 4;
        } else if (str[i] >= 'A' && str[i] <= 'F') {
            hex[j] = (str[i] - 'A' + 10) << 4;
        } else {
            return false;
        }

        if (str[i + 1] >= '0' && str[i + 1] <= '9') {
            hex[j] = (hex[j] & 0xf0) | ((str[i + 1] - '0') & 0x0f);
        } else if (str[i + 1] >= 'a' && str[i + 1] <= 'f') {
            hex[j] = (hex[j] & 0xf0) | ((str[i + 1] + 10 - 'a') & 0x0f);
        } else if (str[i + 1] >= 'A' && str[i + 1] <= 'F') {
            hex[j] = (hex[j] & 0xf0) | ((str[i + 1] + 10 - 'A') & 0x0f);
        } else {
            return false;
        }
    }
    return true;
}


const hex_digit_to_int = (hex) => {
    if ('A' <= hex && hex <= 'F') {
        return 10 + hex - 'A';
    }
    if ('a' <= hex && hex <= 'f') {
        return 10 + hex - 'a';
    }
    if ('0' <= hex && hex <= '9') {
        return hex - '0';
    }
    return -1;
}

const hex_string_to_binary = (hex_string, buf, buf_size) => {
    const num_char = hex_string.length;

    if (num_char !== buf_size * 2) {
        return 0;
    }
    for (let i = 0; i < num_char; i += 2) {
        const digit0 = hex_digit_to_int(hex_string[i]);
        const digit1 = hex_digit_to_int(hex_string[i + 1]);

        if (digit0 < 0 || digit1 < 0) {
            return 0;
        }
        buf[i / 2] = (digit0 << 4) + digit1;
    }

    return buf_size;
}


const get_bit = (source, bit) => {
    return (source & (1 << bit)) >> bit;
}


export {
    bytes_to_hex,
    generate_uuid,
    hex_to_string,
    string_to_hex,
    hex_digit_to_int,
    hex_string_to_binary,
    get_bit
}