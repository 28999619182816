import Helper from "../../../library/Helper";
import moment from "moment/moment";
import Lifts from "../../../api/liftApi";
import HttpCodes from "../../../library/HttpCodes";

const findJourneys = async (lift, dataViewSet, setLoadingTimeline) => {
    try {
        setLoadingTimeline(true)

        // console.log("Journey Data View set = ", dataViewSet)
        let query = {
            device_id: lift.device.device_id,
            starting_at: dataViewSet.range_start,
            ending_at: dataViewSet.range_end,
            limit: 5000,
        }

        let result = await Lifts.postDeviceJourneyTimelineQuery(query)
        if (result.status === HttpCodes.HttpOK) {
            let jg = calculateGroupedJourneys(result.data, dataViewSet)
            return calculateJourneys(result.data, dataViewSet)
        }

        return null
    }
    catch(e) {
        console.log("Problem loading data distributions - ", e.message)
        return null
    }
    finally {
        setLoadingTimeline(false)
    }
}


const isValidFloor = (f) => {
    switch (f) {
        case 0x55 : return false ;
        case 0xff : return false ;
        case null : return false ;
        default : return true ;
    }
}

const calculateGroupedJourneys = (data, dataViewSet) => {
    // console.log("Calculating journey raw data (grouped) : ", data)
    let journeyGroups = [] ;
    let journeyList = [] ;
    let journey = null ;

    let new_stats = {
        total_up_journeys: 0,
        total_down_journeys: 0,
    }
    // NOTE: This is a VERY simplistic journey calculation (purely based on button presses for now)
    for (let i = 0; i < data.length; i++) {
        let d = data[i];

        // A bit of data modification to handle floor values for lifts prior to firmware V5
        if (Helper.isNullOrUndefined(d.r_encoder_floor)) {
            // A very basic test to check whether we are pre v5 firmware
            // Current floor has values (1 = Bottom, 3 = Top, else unknown)
            if (d.r_current_floor === 1) {
                d.r_current_floor = 0
            }
            else if (d.r_current_floor === 3) {
                d.r_current_floor = 1
            }
            else {
                d.r_current_floor = 0x55
            }
        }

        let anyButton = d.remotedown === '1' || d.remoteup === '1' || d.copup === '1' || d.copdown === '1'
        let copButton = (d.copup === '1' || d.copdown === '1') && (d.remotedown === '0' || d.remoteup === '0')
        let remButton = (d.remotedown === '1' || d.remoteup === '1')
        if (anyButton) {
            if (journey === null) {
                journey = {
                    start_voltage : d.voltage,
                    start: d.t_rtc ? d.t_rtc : d.received_at,
                    end: d.t_rtc ? d.t_rtc : d.received_at,
                    starting_floor : d.r_current_floor,
                    last_known_floor : d.r_last_known_floor,
                    end_floor : d.r_current_floor,
                    start_m1_encoder : d.e_motor1_enc_count,
                    end_m1_encoder : 0,
                    distance : 0,
                    data_points : 1,
                    travel_direction : null,
                    duration: 0,
                    occupied : copButton,
                    using_remote : remButton,
                    load_sum : d.load,
                    load : 0,
                    at_valid_floor : isValidFloor(d.r_current_floor),
                    starts_at_valid_floor : isValidFloor(d.r_current_floor),
                }
            }
            else {
                journey.data_points++
                journey.load_sum += d.load
            }
        }
        else if (journey !== null && journey.start_m1_encoder !== d.e_motor1_enc_count) {
            journey.end_voltage = d.voltage
            journey.voltage_delta = journey.end_voltage - journey.start_voltage
            journey.end = d.t_rtc ? d.t_rtc : d.received_at
            journey.end_floor = d.r_current_floor
            journey.end_m1_encoder = d.e_motor1_enc_count
            journey.distance = Math.abs(journey.end_m1_encoder - journey.start_m1_encoder) * 0.0174 / 1000
            journey.load_sum += d.load
            journey.rtc = d.t_rtc
            journey.at_valid_floor = isValidFloor(d.r_current_floor)
            journey.ends_at_valid_floor = isValidFloor(d.r_current_floor)

            if (journey.end_m1_encoder === journey.start_m1_encoder) {
                journey.travel_direction = 'stationary'
            }
            else {
                journey.travel_direction = journey.end_m1_encoder > journey.start_m1_encoder ? 'up' : 'down'
            }

            let is_up = journey.travel_direction === "up" && (journey.starting_floor !== journey.end_floor) && isValidFloor(journey.end_floor)
            let is_down = journey.travel_direction === "down" && (journey.starting_floor !== journey.end_floor) && isValidFloor(journey.end_floor)

            if (journey.travel_direction === "up") {
                journey.direction_ind = journey.at_valid_floor ? 'vertical_align_top' : 'arrow_upward'
            }
            else if (journey.travel_direction === "down") {
                journey.direction_ind = journey.at_valid_floor ? 'vertical_align_bottom' : 'arrow_downward'
            }
            else {
                journey.direction_ind = 'vertical_align_center'
            }

            journey.direction_colour = journey.at_valid_floor ? 'green' : 'red'


            if (is_up) {
                new_stats.total_up_journeys++
            }
            if (is_down) {
                new_stats.total_down_journeys++
            }

            // need to set the journey duration from the start and end times
            journey.duration = moment(journey.end).diff(moment(journey.start), 'seconds')
            journey.load = journey.load_sum / journey.data_points

            if (journey.starts_at_valid_floor && journey.ends_at_valid_floor) {
                journeyGroups.push([journey])
            }
            else if (journey.ends_at_valid_floor) {
                // find the last element in the journeyList array which starts at a valid floor
                let lastValid = journeyList.findIndex(j => j.starts_at_valid_floor)
                if (lastValid >= 0) {
                    // take a slice of the journeyList array from the last valid floor to the end
                    let slice = journeyList.slice(lastValid)
                    // add the current journey to the slice
                    slice.push(journey)
                    // add the slice to the journeyGroups array
                    journeyGroups.push(slice)
                    // remove the slice from the journeyList array
                    journeyList = journeyList.slice(0, lastValid)
                }
            }
            else {
                journeyList.push(journey)
            }

            // journeyList.push(journey)
            journey = null
        }
    }

    let latestDatapoint = null
    if (dataViewSet.data.length > 0) {
        latestDatapoint = dataViewSet.data[data.length - 1]
    }
    else {
        latestDatapoint = null
    }

    return {
        timeline : data,
        latest_datapoint : latestDatapoint,
        stats : new_stats,
        journeys : journeyGroups.reverse()
    }
}


const calculateJourneys = (data, dataViewSet) => {
    // console.log("Calculating journey raw data : ", data)
    let journeyList = [] ;
    let journey = null ;

    let new_stats = {
        total_up_journeys: 0,
        total_down_journeys: 0,
    }
    // NOTE: This is a VERY simplistic journey calculation (purely based on button presses for now)
    for (let i = 0; i < data.length; i++) {
        let d = data[i];

        // A bit of data modification to handle floor values for lifts prior to firmware V5
        if (Helper.isNullOrUndefined(d.r_encoder_floor)) {
            // A very basic test to check whether we are pre v5 firmware
            // Current floor has values (1 = Bottom, 3 = Top, else unknown)
            if (d.r_current_floor === 1) {
                d.r_current_floor = 0
            }
            else if (d.r_current_floor === 3) {
                d.r_current_floor = 1
            }
            else {
                d.r_current_floor = 0x55
            }
        }

        let anyButton = d.remotedown === '1' || d.remoteup === '1' || d.copup === '1' || d.copdown === '1'
        let copButton = (d.copup === '1' || d.copdown === '1') && (d.remotedown === '0' || d.remoteup === '0')
        let remButton = (d.remotedown === '1' || d.remoteup === '1')
        if (anyButton) {
            if (journey === null) {
                journey = {
                    start_voltage : d.voltage,
                    start: d.t_rtc ? d.t_rtc : d.received_at,
                    end: d.t_rtc ? d.t_rtc : d.received_at,
                    starting_floor : d.r_current_floor,
                    last_known_floor : d.r_last_known_floor,
                    end_floor : d.r_current_floor,
                    start_m1_encoder : d.e_motor1_enc_count,
                    end_m1_encoder : 0,
                    distance : 0,
                    data_points : 1,
                    travel_direction : null,
                    duration: 0,
                    occupied : copButton,
                    using_remote : remButton,
                    load_sum : d.load,
                    load : 0,
                    at_valid_floor : isValidFloor(d.r_current_floor),
                }
            }
            else {
                journey.data_points++
                journey.load_sum += d.load
            }
        }
        else if (journey !== null && journey.start_m1_encoder !== d.e_motor1_enc_count) {
            journey.end_voltage = d.voltage
            journey.voltage_delta = journey.end_voltage - journey.start_voltage
            journey.end = d.t_rtc ? d.t_rtc : d.received_at
            journey.end_floor = d.r_current_floor
            journey.end_m1_encoder = d.e_motor1_enc_count
            journey.distance = Math.abs(journey.end_m1_encoder - journey.start_m1_encoder) * 0.0174 / 1000
            journey.load_sum += d.load
            journey.rtc = d.t_rtc
            journey.at_valid_floor = isValidFloor(d.r_current_floor)

            if (journey.end_m1_encoder === journey.start_m1_encoder) {
                journey.travel_direction = 'stationary'
            }
            else {
                journey.travel_direction = journey.end_m1_encoder > journey.start_m1_encoder ? 'up' : 'down'
            }

            let is_up = journey.travel_direction === "up" && (journey.starting_floor !== journey.end_floor) && isValidFloor(journey.end_floor)
            let is_down = journey.travel_direction === "down" && (journey.starting_floor !== journey.end_floor) && isValidFloor(journey.end_floor)

            if (journey.travel_direction === "up") {
                journey.direction_ind = journey.at_valid_floor ? 'vertical_align_top' : 'arrow_upward'
            }
            else if (journey.travel_direction === "down") {
                journey.direction_ind = journey.at_valid_floor ? 'vertical_align_bottom' : 'arrow_downward'
            }
            else {
                journey.direction_ind = 'vertical_align_center'
            }

            journey.direction_colour = journey.at_valid_floor ? 'green' : 'red'


            if (is_up) {
                new_stats.total_up_journeys++
            }
            if (is_down) {
                new_stats.total_down_journeys++
            }

            // need to set the journey duration from the start and end times
            journey.duration = moment(journey.end).diff(moment(journey.start), 'seconds')
            journey.load = journey.load_sum / journey.data_points
            journeyList.push(journey)
            journey = null
        }
    }

    let latestDatapoint = null
    if (dataViewSet.data.length > 0) {
        latestDatapoint = dataViewSet.data[data.length - 1]
    }
    else {
        latestDatapoint = null
    }

    return {
        timeline : data,
        latest_datapoint : latestDatapoint,
        stats : new_stats,
        journeys : journeyList.reverse()
    }
}

export const JourneyCalculator = {
    calculateJourneys,
    calculateGroupedJourneys,
    findJourneys,
    isValidFloor
}