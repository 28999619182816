

export const drawCommsMarkers = (canvas, ctx, snapshot, markers, offsetY, boundariesRef) => {
    const bd = boundariesRef.current ;

    // Draw digital_adc along the y-axis
    ctx.save() ;
    let hidden = 0 ;
    markers.forEach((marker, index) => {
        let actualIndex = index - hidden ;
        const eventY = offsetY + (actualIndex * bd.mk_comms_item_h) + bd.mk_comms_top_pad;

        if (marker.instances.length === 0) {
            return ;
        }

        for (let event of marker.instances) {
            const startX = bd.left_pad + event.ts.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;
            const endX = bd.left_pad + event.te.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;

            let w = endX - startX ;
            const jwidth = Math.max(24, w) ;

            if (jwidth <= 24) {
                ctx.fillStyle = marker.color;
                ctx.beginPath();
                ctx.moveTo(startX, eventY + bd.mk_comms_bar_h / 2); // Point of the triangle
                ctx.lineTo(startX + 20, eventY); // Top corner
                ctx.lineTo(startX + 20, eventY + bd.mk_comms_bar_h); // Bottom corner
                ctx.closePath();
                ctx.fill();
            }
            else {
                ctx.fillStyle = marker.color;
                ctx.fillRect(startX, eventY, endX - startX, bd.mk_comms_bar_h);
            }

            // draw a vertical line down from the top of the marker display_section to the bottom of the canvas
            ctx.beginPath();
            ctx.moveTo(startX, eventY);
            ctx.lineTo(startX, bd.height - bd.xax_lbl_h);
            ctx.setLineDash([5, 5]); // Set the line dash pattern
            ctx.strokeStyle = marker.color;
            ctx.stroke();

            // draw a line down from the end of the marker display_section to the bottom of the canvas
            ctx.beginPath();
            ctx.moveTo(endX, eventY);
            ctx.lineTo(endX, bd.height - bd.xax_lbl_h);
            ctx.setLineDash([5, 5]); // Set the line dash pattern
            ctx.strokeStyle = marker.color;
            ctx.stroke();

        }
    });
    ctx.restore() ;
}
