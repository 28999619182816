import React, {useEffect} from "react";


export const DigitalOnOffItemSelector = ({io_spec, viewSpec, onItemToggle}) => {

    const [selectedADC, setSelectedADC] = React.useState({});

    useEffect(() => {
        console.log("[ADC] Main IO Spec", io_spec);
        console.log("[ADC] View Spec Misc : ", viewSpec);
        if (io_spec) {

            let items = {} ;

            for (let adc_key of Object.keys(io_spec.item_adc_map)) {
                let view_item = io_spec.data_items[adc_key] ;
                let item = io_spec.item_adc_map[adc_key] ;

                if (item.adc === 'O') {
                    continue ;
                }

                let inViewSpec = viewSpec.items.find((k) => k.key === adc_key) !== undefined ;
                // console.log("[ADC]: ", inViewSpec, item, viewSpec.items) ;
                let newItem = {
                    adc: item.adc,
                    bit : item.bit,
                    key: adc_key,
                    view_item: view_item,
                    adc_item : {...item},
                    modified: false,
                    inViewSpec: inViewSpec
                } ;
                items[adc_key] = newItem ;
            }

            console.log("[ADC] - Setting Selected ADC Items : ", items) ;
            setSelectedADC(items);
        }
        else {
            setSelectedADC({});
        }

    }, [viewSpec]);

    const toggleInput = (gi) => {
        let adc_key = `${gi.adc}_${gi.bit}`;
        let newSelected = {...selectedADC};
        if (newSelected[adc_key]) {
            onItemToggle(gi, adc_key, false) ;
            delete newSelected[adc_key];
        } else {
            newSelected[adc_key] = gi;
            onItemToggle(gi, adc_key, true) ;
        }
        setSelectedADC(newSelected);
        console.log("Selected ADC", newSelected);
    }

    const isInputSelected = (gi) => {
        // let adc_key = `${gi.adc}_${gi.bit}`;
        return selectedADC[gi.key].inViewSpec ? true : false;
    }

    return (
        <div>
            <h5>Select Digital On/Off Data item</h5>
            <small>ADC data items will display as events with defined start and end points on the timeline view. Short term events will display as a left pointing triangle to the start time.</small>

            <div style={{
                border: '0.5px solid gray',
                borderRadius: '5px',
                padding: '5px',
                marginBottom: '20px'
            }}>
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <div>
                        {io_spec.groups.map((g, i) => {
                            if (g.type !== "ADC") {
                                return null ;
                            }
                            return (
                                <div style={{
                                    display: 'inline-block',
                                    padding: '10px',
                                    verticalAlign: 'top'
                                }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        fontSize: 'medium'
                                    }}>{g.name}</div>
                                    <small>{g.desc}</small>
                                    <div style={{paddingTop: '10px'}}>
                                        {g.io.map((gi, i) => {
                                            let targetAdc = selectedADC[gi]
                                            if (!targetAdc) {
                                                return null
                                            }

                                            let selected = isInputSelected(targetAdc);
                                            return (
                                                <div>
                                                    <div
                                                        style={{
                                                            width: '50px',
                                                            display: 'inline-block'
                                                        }}
                                                        onClick={() => toggleInput(targetAdc)}
                                                        className={selected ? "diacell-selected" : "diacell-off"}>{targetAdc.adc}{targetAdc.bit}</div>
                                                    {targetAdc.adc_item.desc}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>

                    <br/>
                </div>
            </div>
        </div>
    )
}
