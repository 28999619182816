
const valueLowHighNormalisers = {
    "v_bat_voltage_mv" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "v_shadow_voltage_mv" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "v_chrg_last_volt_mv" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "h_bat_volt_post_mv" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "h_bat_volt_after_recovery" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "vr_volt_low_last_run" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "vr_volt_high_last_run" : {low: (v) => Math.min(0, v), high: (v) => Math.max(30000, v)},
    "e_motor1_enc_count" : {low: (v) => v - 1000, high: (v) => v + 1000},
    "l_lift_angle" : {low: (v) => v * 0.99, high: (v) => v * 1.01},
}


export {valueLowHighNormalisers};