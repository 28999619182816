

export const drawVerticalLine = (canvas, ctx, ld, x, boundariesRef) => {
    // Draw the vertical red line
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, boundariesRef.current.height);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 0.5;
    ctx.stroke();
    ctx.restore();

    let snapshot = ld.active_snapshot ;
    let startTime = snapshot.start_time.local();
    const seconds = (x - boundariesRef.current.left_pad) / boundariesRef.current.step_x_sec ;
    const newTime = startTime.clone().add(seconds, 'seconds');

    // Draw the label
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(newTime.format("HH:mm:ss"), x, 30);
}