import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext, DisplayContext} from "../../ResponsiveApp";
import {LiftDataContext} from "./LiftDataNavigatorContext";
import moment from "moment";
import Helper from "../../library/Helper";
import Notes from "../../api/notesApi";
import HttpCodes from "../../library/HttpCodes";
import {LoadingBanner} from "../../library/LoadingBanner";
import ButtonGlyph from "../../library/buttonGlyph";


// const dummyNotes = [
//     {
//         note_id : 1,
//         author: 'Andy Calderbank',
//         created: '2021-09-01 12:00:00',
//         edited: '2021-09-01 12:00:00',
//         content : "This is a note about the lift. It is a very long note that goes",
//         target_start_date : null,
//         target_end_date : null,
//         target_selected_date : null,
//         raise_service_breakdown_email : true,
//         include_selected_range : false
//     },
//     {
//         note_id : 2,
//         author: 'Andy Calderbank',
//         created: '2021-09-01 12:00:00',
//         edited: '2021-09-01 12:00:00',
//         content : "The first note about this lift",
//         target_start_date : '2021-09-01 12:00:00',
//         target_end_date : '2021-09-01 12:00:00',
//         target_selected_date : '2021-09-01 12:00:00',
//         raise_service_breakdown_email : false,
//         include_selected_range : true
//     }
// ]


export const LiftNotesManagement = ({}) => {
    const { authorisation } = useContext(AuthContext);
    const display = useContext(DisplayContext);
    const { dataViewSet, findDataRange, lift } = useContext(LiftDataContext);

    const [addingNote, setAddingNote] = useState(false);
    const [notes, setNotes] = useState([]);
    const [noteContent, setNoteContent] = useState('');
    const [raiseServiceBreakdownEmail, setRaiseServiceBreakdownEmail] = useState(false);
    const [includeSelectedRange, setIncludeSelectedRange] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Load notes from server
        if (lift) {
            const runner = async () => {
                await loadNotes(lift.device_id);
            }

            runner();
        }
    }, [lift]);

    const loadNotes = async (lift_id) => {
        try {
            setLoading(true);
            let notes = await Notes.loadNotesForLift(lift_id);
            console.log("Loaded Lift Notes", notes);
            if (notes.status === HttpCodes.HttpOK) {
                setNotes(notes.data);
            }
            else {
                setNotes([]);
            }
        }
        catch(e) {
            console.log("Error loading notes", e);
        }
        finally {
            setLoading(false);
        }
    }

    const save = async () => {
        try {
            let userid = authorisation.account.userid;
            let author = authorisation.account.firstname + ' ' + authorisation.account.surname;
            let new_note = {
                note_id: null,
                user_id: userid,
                target_lift_id: lift.device_id,
                author: author,
                content: noteContent,
                target_start_date: null,
                target_end_date: null,
                target_selected_datapoint: null,
                raise_service_breakdown_email: raiseServiceBreakdownEmail,
                is_system_generated: false,
            };

            if (includeSelectedRange) {
                new_note.target_start_date = dataViewSet.range_start;
                new_note.target_end_date = dataViewSet.range_end;
                new_note.target_selected_datapoint = dataViewSet.current?.device_data_id;
            }

            let note_id_res = await Notes.createNote(new_note);
            console.log("Save Note Result = ", note_id_res);
            if (note_id_res.status === HttpCodes.HttpOK) {
                await loadNotes(lift.device_id);
            }

            setAddingNote(false);
            setNoteContent('');
            setRaiseServiceBreakdownEmail(false);
            setIncludeSelectedRange(false);
        }
        catch (e) {
            console.error("Error saving note", e);
        }
    }

    const removeNote = async (note) => {
        try {
            let res = await Notes.deleteNote(note.note_id);
            if (res.status === HttpCodes.HttpOK) {
                await loadNotes(lift.device_id);
            }
        }
        catch(e) {
            console.error("Error removing note", e);
        }
    }

    const loadNoteTargetDateRange = async (note) => {
        if (!note || !note.target_start_date || !note.target_end_date) {
            return ;
        }

        console.log("Loading Note Target Date Range", note);
        await findDataRange(note.target_start_date, note.target_end_date, note.target_selected_datapoint)
    }

    return (
        <div style={{width: '100%'}}>
            <h3>Notes</h3>
            <LoadingBanner loading={loading} title="Loading Lift Notes" />
            <button className={"button-small button-refresh"} disabled={addingNote} onClick={() => setAddingNote(true)}>
                <span className="material-symbols-outlined" style={{ verticalAlign: '-7px' }}>add</span>New Note
            </button>
            {addingNote &&
                <div className="lift-note-edit" style={{ display: 'flex', flexDirection: 'column', height: '250px' }}>
                    <div style={{ padding: '5px', flex: 1 }}>
                        <textarea
                            style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
                            value={noteContent}
                            onChange={(e) => setNoteContent(e.target.value)}
                            placeholder={"enter note detail here"}
                        ></textarea>
                    </div>
                    <div style={{ display: 'flex', padding: '5px', flexDirection: 'column', gap: '5px' }}>
                        <label style={{ fontSize: 'small' }}>
                            <input type="checkbox" checked={raiseServiceBreakdownEmail} onChange={(e) => setRaiseServiceBreakdownEmail(e.target.checked)} /> Raise Service Breakdown Email
                        </label>
                        <label style={{ fontSize: 'small' }}>
                            <input type="checkbox" checked={includeSelectedRange} onChange={(e) => setIncludeSelectedRange(e.target.checked)} /> Include Selected Range
                        </label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
                        <button className={"button-cancel"} onClick={() => setAddingNote(false)}>Cancel</button>
                        <button className={"button-save"} onClick={save}>Save</button>
                    </div>
                </div>
            }

            {notes.map((note, idx) => {
                return (
                    <div className="lift-note" key={idx}>
                        <div style={{ marginBottom: '10px' }}><i>{note.content}</i></div>
                        <div style={{ marginTop: '10px', padding: '5px' }}>
                            {note.raise_service_breakdown_email &&
                                <>
                                {(!note.service_breakdown_email_sent) && <div style={{ color: 'red' }}><span className="material-symbols-outlined" style={{ verticalAlign: '-7px', paddingRight: '10px' }}>support_agent</span>Service Breakdown Will Be Notified</div>}
                                {(note.service_breakdown_email_sent) && <div style={{ color: 'red' }}><span className="material-symbols-outlined" style={{ verticalAlign: '-7px', paddingRight: '10px' }}>support_agent</span>Service Breakdown Notified</div>}
                                </>
                            }
                            {note.target_start_date &&
                                <>
                                    <div style={{ color: 'black' }}><span className="material-symbols-outlined" style={{ verticalAlign: '-7px', paddingRight: '10px' }}>date_range</span>
                                        <div style={{display: 'inline-block', fontSize: 'smaller'}}>{Helper.dbTimeToDisplayTime(note.target_start_date)} => {Helper.dbTimeToDisplayTime(note.target_end_date)}</div>
                                    </div>
                                    <button className={"button-small button-refresh"} onClick={() => loadNoteTargetDateRange(note)}>Load this data</button>
                                </>

                            }
                        </div>
                        <div>
                            <div><small><b>Created By {note.author}</b></small></div>
                            <div><small>Created {note.created_at}</small></div>
                        </div>
                        <div style={{ marginTop: '10px', padding: '5px' }}>
                            {/*<div className="material-symbols-outlined" style={{ fontSize: '18px', color: `green`, cursor: 'pointer' }} onClick={() => { }}>edit</div>*/}
                            <div className="material-symbols-outlined" style={{ paddingLeft: '10px', fontSize: '20px', color: `red`, cursor: 'pointer' }} onClick={() => removeNote(note)}>delete</div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};