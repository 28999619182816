import React, {useEffect, useState} from "react";


export const GeneralDatatemSelector = ({io_spec, viewSpec, onAddItem, onUpdate, onRemove}) => {

    const [genDataItems, setGenDataItems] = useState([]);

    useEffect(() => {
        console.log("[MISC] Timeline IO Changed ", io_spec);
        console.log("[MISC] View Spec Misc : ", viewSpec);
        if (io_spec) {
            let items = Object.values(io_spec.data_items).filter((item) => item.type !== "ANALOGUE" && item.type !== "ADC" && item.type !== "OUT" && item.view !== "L475LOG" && item.view !== "F301LOG") ;
            console.log("[MISC] Applicable General Data Items : ", items) ;
            let result = []
            for (let item of items) {
                let vs = viewSpec.items.find((k) => k.key === item.key);
                let newItem = {
                    ...item,
                    lookup_selected : [],
                    modified : false,
                    inViewSpec: vs !== undefined
                }

                if (item.type === "LOOKUP") {
                    // console.log("Lookup Item : ", item, vs) ;
                    if (vs?.config?.lookups?.[io_spec.version]) {
                        newItem.lookup_selected = [...(vs?.config.lookups?.[io_spec.version] ?? [])] ;
                    }
                    else {
                        for (let k of Object.keys(item.lookup)) {
                            if (item.lookup[k].show) {
                                newItem.lookup_selected.push(k);
                            }
                        }
                    }
                }

                result.push(newItem) ;
            }

            console.log("Setting General Data Items : ", result) ;
            setGenDataItems(result);
        }
        else {
            setGenDataItems([]);
        }
    }, [io_spec, viewSpec]);

    const onSelectLookup = (dataitem, idx) => {
        // // toggle the lookup_selected flag at the specified index
        console.log("Select Lookup : ", dataitem, idx) ;
        let newItems = [...genDataItems] ;
        let item = newItems.find((i) => i.key === dataitem.key) ;
        if (!item) {
            return ;
        }

        console.log("Found Item : ", item) ;
        let lookup_key = Object.keys(dataitem.lookup)[idx] ;
        let lookup_selected = dataitem.lookup_selected ;
        if (lookup_selected.includes(lookup_key)) {
            lookup_selected = lookup_selected.filter((k) => k !== lookup_key) ;
        }
        else {
            lookup_selected.push(lookup_key) ;
        }
        dataitem.lookup_selected = lookup_selected ;
        dataitem.modified = true ;
        setGenDataItems(newItems) ;
        if (item.inViewSpec) {
            onUpdate?.(dataitem) ;
        }
    }

    const onRemoveItem = (dataitem) => {
        onRemove?.(dataitem) ;
    }

    const onSaveitem = (dataitem) => {
        console.log("Save General Item : ", dataitem) ;
        onAddItem?.(dataitem) ;
    }

    return (
        <div style={{
            border: '0.5px solid gray',
            borderRadius: '5px',
            padding: '5px',
            marginBottom: '20px'
        }}>
            <div>
                <small>Miscellaneous/Lookup data items will display as events with defined start and end points on the timeline view. Short term events will display as a left pointing triangle to the start time.</small>
                <div className="item-container-list" style={{padding: '5px'}}>
                    {genDataItems.map((item, idx) => {

                        let target = item ;
                        let in_timeline = item.inViewSpec ;
                        let bckcl = in_timeline ? 'fw-item-editing' : 'fw-item' ;
                        return (
                            <div style={{padding: '5px'}}>
                                <div key={idx} style={{padding: '5px'}} className={bckcl} >
                                    <div className={"fw-item-title"}>{target.label} {target.type === "LOOKUP" ? `[lookup]` : ``}</div>
                                    {/*<small>Key: [{target.key}]</small>*/}
                                    {(target.type === "LOOKUP") &&
                                        <div className={"lookup-list"} style={{marginLeft: '5px', paddingTop: '10px'}}>
                                            {Object.keys(target.lookup).map((k, idx) => {
                                                let lu = target.lookup[k];
                                                return (
                                                    <div key={idx} style={{color: `${lu.color}`}} >
                                                        {/* I want to display a checkbox here */}
                                                        <input type="checkbox" style={{verticalAlign: '-4px'}} checked={item.lookup_selected.includes(k)} onChange={() => onSelectLookup(item, idx)} />
                                                        <b>[{k}] - </b>{lu.label || "- no description -"}
                                                    </div>
                                                )
                                            })
                                            }
                                            <br/>
                                            <button className={"button-small button-info"} disabled={in_timeline} onClick={() => onSaveitem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>add</span>Add</button>
                                            {/*<button className={"button-small button-save"} disabled={!item.modified} onClick={() => onSaveitem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>save</span>Update</button>*/}
                                            <button className={"button-small button-delete"} disabled={!in_timeline} onClick={() => onRemoveItem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>cancel</span>Remove</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}