import React, {useEffect, useState} from "react";


export const AnalogueItemSelector = ({io_spec, viewSpec, onUpdate, onRemove}) => {

    const [analogueItems, setAnalogueItems] = useState([]);

    useEffect(() => {
        console.log("Timeline IO Changed in AnalogueItemSelector", io_spec);
        console.log("View Spec Misc : ", viewSpec);
        if (io_spec) {

            let items = [] ;
            for (let item of Object.values(io_spec.data_items)) {
                if (item.type !== "ANALOGUE") {
                    continue ;
                }

                let inViewSpec = viewSpec.items.find((k) => k.key === item.key) !== undefined ;
                let newItem = {...item, modified: false, inViewSpec: inViewSpec} ;
                items.push(newItem) ;
            }

            setAnalogueItems(items);
        }
        else {
            setAnalogueItems([]);
        }
    }, [io_spec, viewSpec]);

    const onRemoveItem = (dataitem) => {
        console.log("Remove Analogue Item : ", dataitem) ;
        onRemove?.(dataitem) ;
    }

    const onSaveitem = (dataitem) => {
        console.log("Save Analogue Item : ", dataitem) ;
        onUpdate?.(dataitem) ;
    }

    return (
        <div style={{
            border: '0.5px solid gray',
            borderRadius: '5px',
            padding: '5px',
            marginBottom: '20px'
        }}>
            <div>
                <small>Analogue data items will display as a line chart on the timeline view.</small>
                <div className="item-container-list" style={{padding: '5px'}}>
                    {analogueItems.map((item, idx) => {
                        let in_timeline = item.inViewSpec ;
                        let bckcl = in_timeline ? 'fw-item-editing' : 'fw-item' ;

                        return (
                            <div style={{padding: '5px'}}>
                                <div key={idx} style={{padding: '10px', maxWidth: '200px'}} className={bckcl} >
                                    <div  className={"fw-item-title"}>{item.label}</div>
                                    <div style={{width: '100%', fontSize: 'small', fontStyle: 'italic', paddingTop: '5px'}}>
                                        {item.desc || "No Description available"}
                                    </div>
                                    <br/>
                                    <button className={"button-small button-info"} disabled={item.inViewSpec} onClick={() => onSaveitem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>add</span>Add</button>
                                    {/*<button className={"button-small button-save"} disabled={!item.modified} onClick={() => onSaveitem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>save</span>Update</button>*/}
                                    <button className={"button-small button-delete"} disabled={!item.inViewSpec} onClick={() => onRemoveItem(item)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>cancel</span>Remove</button>

                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}