import React, { useEffect, useState } from "react";

export const F301LogItemSelector = ({ active_data_set, io_spec, viewSpec, onAddItem, onUpdate, onRemove }) => {
    const [logItemCounts, setLogItemCounts] = useState({});
    const [l475Lookup, setF301Lookup] = useState(null);

    useEffect(() => {
        console.log("F301 Log - current active data set : ", active_data_set);
        recalculateItemCounts(l475Lookup);
    }, [active_data_set]);

    useEffect(() => {
        let logSpec = io_spec?.data_items?.["s_f301_sr"];
        if (logSpec) {
            let items = [];
            let vs = viewSpec.items.find((k) => k.key === logSpec.key);
            let l475log = {
                ...logSpec,
                lookup_selected : [],
                modified : false,
                inViewSpec: vs !== undefined
            }

            if (vs?.config?.lookups?.[io_spec.version]) {
                l475log.lookup_selected = [...(vs?.config.lookups?.[io_spec.version] ?? [])] ;
            }
            else {
                for (let k of Object.keys(logSpec.lookup)) {
                    if (logSpec.lookup[k].show) {
                        l475log.lookup_selected.push(k);
                    }
                }
            }

            setF301Lookup(l475log);
            console.log("F301 Log Spec : ", logSpec, " created item : ", l475log);
            recalculateItemCounts(l475log);
        }
        else {
            setF301Lookup(null);
        }
    }, [io_spec, viewSpec]);


    const recalculateItemCounts = (l475log) => {
        let countSet = {}
        if (active_data_set) {
            for (let event of active_data_set.data) {
                let l475Code = `${event.s_f301_sr}` ;
                if (countSet[l475Code]) {
                    countSet[l475Code] += 1;
                }
                else {
                    countSet[l475Code] = 1;
                }
            }
        }

        setLogItemCounts(countSet);
    }

    const onSelectLookup = (dataitem, idx) => {
        // toggle the lookup_selected flag at the specified index
        console.log("Select Lookup : ", dataitem, idx) ;
        let newLog = {...l475Lookup} ;
        let is_set = newLog.lookup_selected.includes(dataitem.key);
        if (is_set) {
            newLog.lookup_selected = newLog.lookup_selected.filter((k) => k !== dataitem.key) ;
        }
        else {
            newLog.lookup_selected.push(dataitem.key) ;
        }

        setF301Lookup(newLog) ;
        if (l475Lookup.inViewSpec) {
            onUpdate?.(newLog) ;
        }
    }

    const setAll = (value) => {
        let lookups = [] ;
        if (value) {
            for (let k of Object.keys(l475Lookup.lookup)) {
                lookups.push(k) ;
            }
        }

        let newLog = {...l475Lookup} ;
        newLog.lookup_selected = lookups ;
        setF301Lookup(newLog) ;
        if (l475Lookup.inViewSpec) {
            onUpdate?.(newLog) ;
        }
    }

    const addF301Log = () => {
        onAddItem?.(l475Lookup) ;
    }

    const removeF301Log = () => {
        if (l475Lookup.inViewSpec) {
            onRemove?.(l475Lookup) ;
        }
    }

    const lookup = Object.values(l475Lookup?.lookup ?? {});
    const columns = [];
    for (let i = 0; i < lookup.length; i += 10) {
        columns.push(lookup.slice(i, i + 10));
    }

    return (
        <div style={{ border: '0.5px solid gray', borderRadius: '5px', padding: '5px', marginBottom: '20px' }}>
            <div>
                <h4>F301 Log Items</h4>
                <small>F301 log items will display as events with defined start and end points on the timeline view. Short term events will display as a left pointing triangle to the start time.</small>

                <div>
                    <button className={"button-small button-info"} disabled={l475Lookup?.inViewSpec} onClick={addF301Log}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>add</span>Add</button>
                    <button className={"button-small button-delete"} disabled={!l475Lookup?.inViewSpec} onClick={removeF301Log}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>cancel</span>Remove</button>
                    <button className={"button-small button-info"} onClick={() => setAll(false)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>toggle_off</span>Clear All</button>
                    <button className={"button-small button-info"} onClick={() => setAll(true)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>toggle_on</span>Set All</button>
                </div>
                <div className="item-container-list" style={{padding: '5px'}}>
                    {columns.map((column, colIdx) => (
                        <div key={colIdx} className="log-items-column">
                            {column.map((item, idx) => {
                                let is_selected = l475Lookup.lookup_selected.includes(item.key);
                                let item_count = logItemCounts[item.key] ?? 0;
                                return (
                                    <div key={idx} className={item_count > 0 ? 'log-item' : 'log-item-zero'}>
                                        <div style={{display: 'inline-block'}}>
                                            <input type="checkbox" checked={is_selected}
                                                   style={{verticalAlign: '-2px', display: 'inline-block'}}
                                                   onChange={() => onSelectLookup(item, (colIdx * 10) + idx)}/>
                                            <div className="log-item-code">{item.code} [{item_count}]</div>
                                        </div>

                                        <div className="log-item-desc">{item.label}</div>
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};