import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment";


import {LiftDataContext} from "../LiftDataNavigatorContext";
import SelectDropdown from "../../../library/SelectDropdown";
import SessionState from "../../../library/SessionState";
import {AnalogueItemSelector} from "./AnalogueItemSelector";
import {DigitalOnOffItemSelector} from "./DigitalOnOffItemSelector";
import Users from "../../../api/userApi";
import LocalState from "../../../library/LocalState";
import HttpCodes from "../../../library/HttpCodes";
import Cases from "../../../api/caseEventApi";
import {valueLowHighNormalisers} from "./DefaultTimelineCriteria";
import {JourneyCalculator} from "../journeys/JourneyCalculator";
import Lifts from "../../../api/liftApi";
import {GeneralDatatemSelector} from "./GeneralDatatemSelector";
import {L475LogItemSelector} from "./L475LogItemSelector";
import {F301LogItemSelector} from "./F301LogItemSelector";
import {drawDataPointLines} from "./renderers/drawDataPointLines";
import {drawAxisLabels} from "./renderers/drawAxisLabels";
import {drawCommsMarkers} from "./renderers/drawCommsMarkers";
import {drawTriggerMarkers} from "./renderers/drawTriggerMarkers";
import {drawEventChartItem} from "./renderers/drawTimeline";
import {drawLineChartItem} from "./renderers/drawLineCharts";
import {drawJourneys} from "./renderers/drawJourneys";
import {drawDragSelection} from "./renderers/drawDragSelection";
import {drawVerticalLine} from "./renderers/drawVerticalLine";
import {findClosestAfter, findClosestBefore} from "./helpers/findDataPointHelpers";
import {ChartDataMapper} from "./ChartDataMapper";
import {AuthContext} from "../../../ResponsiveApp";
import {get_bit} from "../../live-connect/common_decode";
import {DigitalOutputSelector} from "./DigitalOutputSelector";


const emptyDragEvent = {
    drag_start_x : null,
    drag_end_x : null,
    drag_start_y : null,
    drag_end_y : null,
    drag_start_index : null,
    drag_end_index : null,
    drag_start_time : null,
    drag_end_time : null,
    scale_x : null,
    delta_x : null,
}

const DefaultViewConfig = {
    view_selected : null,
    views : []
}

export const LiftTimelineCanvas = ({notesOpen}) => {
    const {authorisation} = useContext(AuthContext)
    const {dataViewSet, lift, liftSpecificationRef, moveToSelectedTime, move} = useContext(LiftDataContext) ;

    const canvasRef = useRef(null);

    const [specificationName, setSpecificationName] = useState(null);
    const [isGlobalSpec, setIsGlobalSpec] = useState(false);
    const [selectedView, setSelectedView] = useState(SessionState.getOrDefault("selected_tl_view_spec", null));
    const [showDataSelect, setShowDataSelect] = useState(false);
    const [dataTypeSelection, setDataTypeSelection] = useState(1);

    const [selectedViewSpec, _setSelectedViewSpec] = useState(null);
    const selectedViewSpecRef = useRef(selectedViewSpec);
    const setSelectedViewSpec = (spec) => {
        console.log("SELECTING DROPDOWN ITEM: ", spec) ;
        setSpecificationName(spec.name);
        setIsGlobalSpec(spec.is_global);
        let deep_copy_spec = JSON.parse(JSON.stringify(spec));
        selectedViewSpecRef.current = deep_copy_spec;
        _setSelectedViewSpec(deep_copy_spec);
    }

    const [viewSpecification, _setViewSpecification] = useState(DefaultViewConfig);
    const viewSpecificationRef = useRef(viewSpecification);
    const setViewSpecification = (spec) => {
        viewSpecificationRef.current = spec;
        _setViewSpecification(spec);
    }

    const [targetData, _setTargetData] = useState(null);
    const targetDataRef = useRef(targetData);
    const setTargetData = (data) => {
        targetDataRef.current = data;
        _setTargetData(data);
    }

    const [targetCommsIssues, _setTargetCommsIssues] = useState([]);
    const targetCommsIssuesRef = useRef(targetCommsIssues);
    const setTargetCommsIssues = (comms) => {
        targetCommsIssuesRef.current = comms;
        _setTargetCommsIssues(comms);
    }

    const [targetTriggers, _setTargetTriggers] = useState([]);
    const targetTriggersRef = useRef(targetTriggers);
    const setTargetTriggers = (triggers) => {
        targetTriggersRef.current = triggers;
        _setTargetTriggers(triggers);
    }

    const [journeys, _setJourneys] = useState({
        journeys: [],
        latest_datapoint : null,
        stats : {},
        timeline : []
    });
    const journeysRef = useRef(journeys);
    const setJourneys = (j) => {
        journeysRef.current = j;
        _setJourneys(j);
    }

    const [boundaries, _setBoundaries] = useState(null);
    const boundariesRef = useRef(boundaries);
    const setBoundaries = (bd) => {
        boundariesRef.current = bd;
        _setBoundaries(bd);
    }

    const [activeDragEvent, _setActiveDragEvent] = useState(null);
    const activeDragEventRef = useRef(activeDragEvent);
    const setActiveDragEvent = (dragEvent) => {
        activeDragEventRef.current = dragEvent;
        _setActiveDragEvent(dragEvent);
    }

    const [mouseCursor, _setMouseCursor] = useState(null);
    const mouseCursorRef = useRef(mouseCursor);
    const setMouseCursor = (coordinates) => {
        mouseCursorRef.current = coordinates;
        _setMouseCursor(coordinates);
    }

    const [currentActiveData, _setCurrentActiveData] = useState(null);
    const currentActiveDataRef = useRef(currentActiveData);
    const setCurrentActiveData = (data) => {
        _setCurrentActiveData(data);
        currentActiveDataRef.current = data;
    }

    useEffect(() => {
        window.addEventListener("resize", redrawCanvas);
        const runner = async () => {
            await loadViewSpecifications();
            configureCanvas(canvasRef.current);
            redrawCanvas(); // initial call to get position of the element on mount
        }
        runner();

        return () => window.removeEventListener("resize", redrawCanvas);
    }, []);

    useEffect(() => {
        const initialize = async () => {
            if (!dataViewSet || dataViewSet.empty || !selectedViewSpec) {
                setCurrentActiveData(null);
                return;
            }

            if (!currentActiveDataRef.current || !currentActiveDataRef.current.range_start.isSame(dataViewSet.range_start) || !currentActiveDataRef.current.range_end.isSame(dataViewSet.range_end)) {
                setCurrentActiveData({
                    range_start: dataViewSet.range_start,
                    range_end: dataViewSet.range_end,
                    current_record: dataViewSet.current_record,
                });
                await loadLiftJourneys();
                await loadLiftCases();
                initialiseTimeline(dataViewSet, selectedViewSpec);
            }
            else if (currentActiveDataRef.current && (currentActiveDataRef.current.current_record !== dataViewSet.current_record)) {
                setCurrentActiveData({
                    range_start: dataViewSet.range_start,
                    range_end: dataViewSet.range_end,
                    current_record: dataViewSet.current_record,
                });

                let newActiveData = { ...targetDataRef.current };
                newActiveData.current_record = dataViewSet.current_record;
                setTargetData(newActiveData);
                redrawCanvas();
            }
        };

        initialize();
    }, [dataViewSet, selectedViewSpec]);

    useEffect(() => {
        redrawCanvas();
    }, [notesOpen]);

    useEffect(() => {
        if (selectedViewSpec && dataViewSet && !dataViewSet.empty && currentActiveDataRef && liftSpecificationRef?.current) {
            initialiseTimeline(dataViewSet, selectedViewSpec);
        }
    }, [selectedViewSpec]);

    useEffect(() => {
        console.log("System IO Specification -> ", liftSpecificationRef?.current) ;
    }, [liftSpecificationRef]);

    const loadLiftJourneys = async () => {
        try {
            let journeyResults = await JourneyCalculator.findJourneys(lift, dataViewSet, (v) => {});
            if (journeyResults) {
                console.log("Loaded Journeys", journeyResults);
                setJourneys(journeyResults);
            }
            else {
                setJourneys({
                    journeys: [],
                    latest_datapoint : null,
                    stats : {},
                    timeline : []
                });
            }
        }
        catch(e) {
            console.error(e);
        }
    }

    const loadLiftCases = async () => {
        let vs = dataViewSet ;
        try {
            let criteria = {
                lift_id: lift.device_id,
                start_date : vs.range_start.toISOString(),
                end_date : vs.range_end.toISOString(),
            }

            let caseList = await Cases.timelineSearch(criteria)
            if (caseList.status === HttpCodes.HttpOK) {

                let commsCases = caseList.data.comms_events ;
                let triggers = caseList.data.trigger_events ;

                let commsCaseMarkers = commsCases.reduce((acc,c) => {
                    let item = acc[c.case_name] ;
                    if (!item) {
                        acc[c.case_name] = {
                            key : c.case_name,
                            label : c.case_name,
                            color : 'rgb(88,91,88)',
                            instances: [{ts: moment.utc(c.created_on).local(), te: moment.utc(c.last_updated_on).local()}]
                        }
                        return acc ;
                    }
                    else {
                        item.instances.push({ts: moment.utc(c.created_on).local(), te: moment.utc(c.last_updated_on).local()});
                        return acc ;
                    }
                }, {}) ;

                let triggerMarkers = triggers.reduce((acc,c) => {
                    let item = acc[c.case_name] ;
                    if (!item) {
                        acc[c.case_name] = {
                            key : c.case_name,
                            label : c.case_name,
                            color : 'rgb(222,13,52)',
                            instances: [{ts: moment.utc(c.start_time).local(), te: moment.utc(c.end_time).local()}]
                        }
                        return acc ;
                    }
                    else {
                        item.instances.push({ts: moment.utc(c.start_time).local(), te: moment.utc(c.end_time).local()});
                        return acc ;
                    }
                }, {}) ;

                // console.log("Comms Case Markers", commsCaseMarkers);
                // console.log("Trigger Case Markers", triggerMarkers);
                setTargetCommsIssues(Object.values(commsCaseMarkers));
                setTargetTriggers(Object.values(triggerMarkers));
            }
            else {
                setTargetCommsIssues([])
                setTargetTriggers([])
            }
        }
        catch(e) {
            console.log("Error loading cases: ", e)
        }
        finally {
        }
    }

    const loadViewSpecifications = async () => {
        let views = await Lifts.loadTimelineViewSetsForFwRevision(lift.timeline_io.fw_revision_key);
        console.log("Loaded View Specifications", views.data);
        if (views.status === HttpCodes.HttpOK) {
            let displaySpecs = views.data
            let config = {
                view_selected : SessionState.getOrDefault("selected_tl_view_spec", null),
                views : displaySpecs
            }

            console.log("Created View Specifications", config)

            setViewSpecification(config);
            if (config.view_selected) {
                let spec = config.views.find(v => v.value === config.view_selected);
                if (spec) {
                    setSelectedViewSpec(spec);
                }
                else {
                    let spec = config.views?.[0]
                    setSelectedViewSpec(spec);
                }
            }
            else {
                let spec = config.views?.[0]
                setSelectedViewSpec(spec);
            }
        }

    }

    const saveConfiguration = async (saveState) => {
        try {
            let userid = authorisation.account.userid ;
            let ld = latest_data();
            console.log("SAVE vs= ", selectedViewSpec);
            console.log("SAVE spec ref= ", viewSpecificationRef.current);
            console.log("SAVE chart snapshot= ", ld.active_snapshot);

            let active_set = []
            for (let i = 0 ; i < selectedViewSpecRef.current.items.length; i++) {
                let event = selectedViewSpecRef.current.items[i] ;
                active_set.push({...event});
            }

            let data_update =  {
                global_spec_id : saveState === 'update' ? selectedViewSpec.global_spec_id : null,
                pref_key: LocalState.Keys.TimelineViewSpecs,
                associated_user_id : null,
                pref_name: specificationName,
                pref_value : JSON.stringify({
                    items : active_set,
                    show_journeys : selectedViewSpec.show_journeys,
                    show_triggers : selectedViewSpec.show_triggers
                }),
                temp_items : active_set
            };

            if (saveState === 'copy') {
                data_update.associated_user_id = isGlobalSpec ? null : userid ;
            }
            else if (saveState === 'update') {
                data_update.associated_user_id = selectedViewSpec.associated_user_id ;
            }

            // console.log("UPDATE Action ", saveState, " on ", data_update);

            let result = await Users.saveTimelinePreferences(data_update) ;
            console.log("Save view spec result", result);
            if (result.status === HttpCodes.HttpOK) {
                await loadViewSpecifications();
            }

        }
        catch(e) {
            console.error(e);
        }
    }

    const initialiseTimeline = (dataSet, viewSpec) => {
        console.log("Initialising Timeline with view spec: ", viewSpec);
        console.log("Initialising Timeline with lift io spec: ", liftSpecificationRef?.current);

        let td = null ;
        if (viewSpec && !dataSet.empty) {

            let tl = ChartDataMapper({
                lift   : lift,
                io_spec : liftSpecificationRef.current,
                view_spec : viewSpec,
                dataset  : dataSet,
                adc_map : liftSpecificationRef.current.item_adc_map
            }) ;

            td = {
                view_spec : viewSpec,
                io_spec : liftSpecificationRef.current,
                current_record : dataSet.current_record,
                date_points : tl.ts,
                num_date_points : tl.num_data_points,
                lift_data : tl.target_data,
                snapshots : [],
                valid : true,
                show_journeys : viewSpec.show_journeys,
                show_triggers : true,
                comms_markers : targetCommsIssuesRef.current,
                trigger_markers : targetTriggersRef.current,
                journeys : journeysRef.current,
                tl_markers : targetTriggersRef.current,
                active_snapshot : null,
                analogue_count : tl.analogue_count,
                event_count : tl.event_count
            }
        }
        else {
            console.warn("Error fetching data");
            td = {
                view_spec : viewSpec,
                io_spec : liftSpecificationRef.current,
                current_record : null,
                date_points: [],
                lift_data : null,
                snapshots : [],
                valid : false,
                show_journeys : false,
                show_triggers : true,
                comms_markers : [],
                trigger_markers : [],
                tl_markers : [],
                journeys:  journeysRef.current,
                active_snapshot : null,
                analogue_count : 0,
                event_count : 0
            }
        }

        setTargetData(td);
        generateDataSnapshot(targetDataRef.current, 0, targetDataRef.current.num_date_points - 1);
        redrawCanvas();
    }

    const latest_data = () => {
        return targetDataRef.current;
    }

    const generateDataSnapshot = (full_data, startIndex, endIndex) => {
        let last_snapshot = full_data.snapshots.length > 0 ? full_data.snapshots[full_data.snapshots.length - 1] : null ;
        let data_offset_start = last_snapshot ? last_snapshot.start_index + startIndex: 0 ;

        let snapshot = {
            base_offset : data_offset_start,
            start_time : full_data.date_points[startIndex],
            start_index : startIndex,
            end_time : full_data.date_points[endIndex],
            end_index : endIndex,
            comms_markers : [],
            trigger_markers : [],
            journeys : null,
            analogue_count : full_data.analogue_count,
            event_count : full_data.event_count,
            chart_items : []
        }

        if (!full_data.view_spec || !full_data.lift_data) {
            console.log("Do not have a view spec or any data");
            full_data.snapshots.push(snapshot);
            full_data.active_snapshot = snapshot ;
            return ;
        }

        if (full_data.journeys) {
            let jset = {
                key : "journey",
                show: true,
                up_color: 'rgb(18,156,18)',
                down_color: 'rgb(38,145,220)',
                st_color: 'rgb(115,115,115)',
                instances: []
            }

            for (let j of full_data.journeys.journeys) {
                // Is this journey within the snapshot range?
                let s = moment.utc(j.rtc).local();
                let e = j.end ? moment.utc(j.end).local() : full_data.date_points[endIndex];
                if (s.isAfter(snapshot.end_time) || e.isBefore(snapshot.start_time)) {
                    continue;
                }

                let new_journey = {
                    key : "journey",
                    ts : s,
                    te : e,
                    occupied : j.occupied,
                    direction : j.travel_direction,
                    show: true
                }
                jset.instances.push(new_journey);
            }

            snapshot.journeys = jset ;
        }

        console.log("Generating Snapshot for ", startIndex, " to ", endIndex, " from data ", full_data);
        for (let dx of Object.keys(full_data.lift_data)) {
            let item = full_data.lift_data[dx] ;

            if (item.available === false) {
                // console.log("Skipping unavailable item", item);
                let new_row = {
                    ...item,
                    ss_data : [],
                }

                snapshot.chart_items.push(new_row);
                continue;
            }

            let ss_data = item.data.slice(startIndex, endIndex + 1) ;

            let new_row = {
                ...item,
                ss_data : ss_data,
            }

            // If we've got a previous snapshot then copy across the 'show' and 'shade_back' values from the previous snapshot
            if (last_snapshot) {
                let prev_row = last_snapshot.chart_items.find(r => r.key === dx) ;
                if (prev_row) {
                    new_row.show = prev_row.show ;
                    new_row.shade_back = prev_row.shade_back ;
                }
            }

            if (new_row.is_event) {
                new_row.instances = [] ;

                let restricted_lookups = null ;
                if (item.type === "LOOKUP") {
                    let vs = full_data.view_spec.items.find((k) => k.key === dx);
                    if (vs) {
                        restricted_lookups = vs.config.lookups?.[full_data.io_spec.version] || null;
                    }
                }

                let active_event = null ;
                for (let i = 0 ; i < ss_data.length; i++) {
                    let value = ss_data[i];
                    let ts = full_data.date_points[i + startIndex] ;

                    switch (item.type) {
                        case 'ADC':
                            active_event = processOnOffEvent(dx, value, active_event, ts, i + startIndex, new_row);
                            break;
                        case 'OUT':
                            active_event = processOnOffEvent(dx, value, active_event, ts, i + startIndex, new_row);
                            break;
                        case 'MISC':
                            active_event = processOnOffEvent(dx, value, active_event, ts, i + startIndex, new_row);
                            break;
                        case 'LOOKUP':
                            active_event = processLookupEvent(dx, value, active_event, ts, i + startIndex, new_row, restricted_lookups);
                            break;
                    }
                }

                if (active_event) {
                    active_event.te = full_data.date_points[endIndex];
                    active_event.te_idx = endIndex ;
                    new_row.instances.push(active_event) ;
                }

            }
            else if (new_row.available) {
                new_row.min = Math.min(...ss_data) ;
                new_row.max = Math.max(...ss_data) ;
                let bounds = valueLowHighNormalisers[dx] ;
                if (bounds) {
                    new_row.low_bound = bounds.low(new_row.min);
                    new_row.high_bound = bounds.high(new_row.max);
                }
                else {
                    new_row.low_bound = new_row.min;
                    new_row.high_bound = new_row.max;
                }
            }

            snapshot.chart_items.push(new_row);
        }

        for (let mk of full_data.comms_markers) {
            let key = mk.key ;
            snapshot.comms_markers.push({
                key: key,
                root: mk,
                label: mk.label,
                color: mk.color,
                instances: mk.instances
            });
        }

        for (let mk of full_data.trigger_markers) {
            let key = mk.key ;
            snapshot.trigger_markers.push({
                key: key,
                root: mk,
                label: mk.label,
                color: mk.color,
                instances: mk.instances
            });
        }

        console.log("Created Snapshot = ", snapshot);
        full_data.snapshots.push(snapshot);
        full_data.active_snapshot = snapshot ;
    }

    const redrawCanvas = () => {
        let ld = latest_data();
        if (!ld || !ld.valid || !ld.active_snapshot) {
            return;
        }
        
        const canvas = canvasRef.current;
        if (!canvas) {
            console.warn("Canvas not available");
            return;
        }
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.warn("Context not available");
            return;
        }
        recalcCanvasSizing(canvas, ctx, ld);
        draw(canvas, ctx, ld);
    }

    const recalcCanvasSizing = (canvas, context, dataset) => {
        // console.log("Calculating Canvas Sizing - ", dataset);
        const topPadding = 60 ;         // gap to the top of the canvas (for drawing selected timestamp etc)
        const leftPadding = 10 ;        // gap to the left of the canvas
        const rightPadding = 10 ;       // gap to the right of the canvas
        const xAxisLabelHeight = 80 ;

        const jrnyHeight = dataset.view_spec.show_journeys ? 60 : 0;         // Height of each journey

        const mkCommsTopPad = 10 ;           // Top padding for each marker
        const mkCommsBottomPad = 20 ;        // Bottom padding for each marker
        const mkCommsBarHeight = 15 ;        // Height of the marker display_section
        const mkCommsItemHeight = mkCommsBarHeight + mkCommsTopPad + mkCommsBottomPad ; // Height of each marker item

        const mkTopPad = 10 ;           // Top padding for each marker
        const mkBottomPad = 20 ;        // Bottom padding for each marker
        const mkBarHeight = 15 ;        // Height of the marker display_section
        const mkItemHeight = mkBarHeight + mkTopPad + mkBottomPad ; // Height of each marker item

        const tlTopPad = 20 ;           // Top padding for each timeline event
        const tlBottomPad = 10 ;        // Bottom padding for each timeline event
        const tlBarHeight = 20 ;        // Height of the timeline display_section
        const tlItemHeight = tlBarHeight + tlTopPad + tlBottomPad + 20 ; // Height of each timeline item

        const lcTopPad = 10 ;           // Top padding for each line chart
        const lcBottomPad = 10 ;        // Bottom padding for each line chart
        const lcHeight = 80 ;          // Height of each line chart
        const lcItemHeight = lcHeight + lcTopPad + lcBottomPad ; // Height of each line chart item

        let snapshot = dataset.active_snapshot ;

        let ec = 0 ;
        let ac = 0 ;
        for (let i = 0 ; i < snapshot.chart_items.length; i++) {
            let item = snapshot.chart_items[i] ;
            if (item.is_event) {
                ec += item.show ? 1 : 0 ;
            }
            else {
                ac += item.show ? 1 : 0 ;
            }
        }

        let eventCount = ec ;
        let analogueCount = ac ;
        let commsCount = dataset.comms_markers.length ;
        let trigCount = dataset.trigger_markers.length ;

        const timelineHeight = eventCount * tlItemHeight;
        const lineChartsHeight = analogueCount * lcItemHeight;
        const commsHeight = commsCount * mkCommsItemHeight;
        const triggerHeight = trigCount * mkItemHeight;

        let minHeight = 200 ; // Minimum height for the canvas
        let h = topPadding + timelineHeight + lineChartsHeight + commsHeight + triggerHeight + xAxisLabelHeight + jrnyHeight ;
        const enclosingDiv = canvas.parentElement;
        let axis_width = enclosingDiv.offsetWidth - leftPadding - rightPadding ;
        const totalDurationSecs = (snapshot.end_time - snapshot.start_time) / 1000; // Convert milliseconds to seconds
        let numIntervals = 20 ;
        let stepSecond = axis_width / totalDurationSecs ;

        let hgt = Math.max(minHeight, h);
        let bd = {
            height: hgt,
            width : enclosingDiv.offsetWidth,
            axis_width : axis_width,
            left_pad: leftPadding,
            right_pad: rightPadding,
            top_pad: topPadding,

            mk_comms_chart_start_y: topPadding,
            mk_comms_chart_h: commsHeight,
            mk_comms_top_pad: mkCommsTopPad,
            mk_comms_bottom_pad: mkCommsBottomPad,
            mk_comms_bar_h: mkCommsBarHeight,
            mk_comms_item_h: mkCommsItemHeight,

            mk_trig_chart_start_y: topPadding + commsHeight,
            mk_trig_chart_h: triggerHeight,
            mk_trig_top_pad: mkTopPad,
            mk_trig_bottom_pad: mkBottomPad,
            mk_trig_bar_h: mkBarHeight,
            mk_trig_item_h: mkItemHeight,

            journey_start_y: topPadding + commsHeight + triggerHeight,
            journey_h: jrnyHeight,

            event_start_y : topPadding + commsHeight + triggerHeight + jrnyHeight,

            tl_chart_h: timelineHeight,
            tl_top_pad: tlTopPad,
            tl_bottom_pad: tlBottomPad,
            tl_bar_h: tlBarHeight,
            tl_item_h: tlItemHeight,

            ln_chart_h: lineChartsHeight,
            ln_chart_item_h: lcItemHeight,
            ln_chart_top_pad: lcTopPad,
            ln_chart_bottom_pad: lcBottomPad,

            ln_chart_height: lcHeight,
            xax_lbl_start_y : topPadding + timelineHeight + lineChartsHeight + commsHeight + triggerHeight + jrnyHeight,
            xax_lbl_h : xAxisLabelHeight,
            step_x_sec : stepSecond,
            num_intervals : numIntervals,
            total_duration_secs : totalDurationSecs
        }

        canvas.width = enclosingDiv.offsetWidth;
        canvas.height = bd.height;

        // console.log("Setting Boundaries: ", bd);
        setBoundaries(bd);
    }


    const processOnOffEvent = (eventKey, onOffValue, active_event, ts, index, timeline) => {
        if (onOffValue && !active_event) {
            // Open event
            active_event = {
                ts: ts,
                ts_idx : index,
                te: null
            };
        }
        else if (!onOffValue && active_event) {
            // Close event
            active_event.te = ts;
            active_event.te_idx = index;
            timeline.instances.push(active_event) ;
            active_event = null;
        }

        return active_event
    }

    const processLookupEvent = (eventKey, lookupValue, active_event, ts, index, timeline, restricted_lookups) => {
        if (active_event) {
            if (active_event.value !== lookupValue) {
                let lookupItem = timeline.lookup[`${lookupValue}`]  ;

                if (!lookupItem) {
                    // Got a lookup value which isn't captured in the spec - create a default 'invalid' one
                    lookupItem = {
                        label : `Unknown (${lookupValue})`,
                        color : 'rgb(255,0,0)',
                        display_section : 'all',
                        show : true
                    }
                }

                // Close the current event
                active_event.te = ts;
                timeline.instances.push(active_event);

                // Open a new event
                active_event = {
                    ts: ts,
                    te: null,
                    value: lookupValue,
                    label: lookupItem.label,
                    color: lookupItem.color,
                    display_section: lookupItem.display_section,
                    show: restricted_lookups ? restricted_lookups.includes(`${lookupValue}`) : lookupItem.show
                }
            }
        }
        else {
            let lookupItem = timeline.lookup[`${lookupValue}`]  ;

            if (!lookupItem) {
                // Got a lookup value which isn't captured in the spec - create a default 'invalid' one
                lookupItem = {
                    label : `Unknown (${lookupValue})`,
                    color : 'rgb(255,0,0)',
                    display_section : 'all',
                    show : true
                }
            }

            // Open a new event
            active_event = {
                ts: ts,
                te: null,
                value: lookupValue,
                label: lookupItem.label,
                color: lookupItem.color,
                display_section: lookupItem.display_section,
                show: restricted_lookups ? restricted_lookups.includes(lookupValue) : lookupItem.show
            }
        }

        return active_event
    }

    const draw = (canvas, ctx, ld) => {
        ctx.clearRect(0, 0, canvas.width, boundariesRef.current.height);

        drawDataPointLines(canvas, ctx, ld, boundariesRef);
        drawAxisLabels(canvas, ctx, ld, boundariesRef);
        if (ld.show_journeys) {
            drawJourneys(canvas, ctx, ld, boundariesRef);
        }

        let snapshot = ld.active_snapshot ;
        drawCommsMarkers(canvas, ctx, snapshot, snapshot.comms_markers, boundariesRef.current.mk_comms_chart_start_y, boundariesRef);
        drawTriggerMarkers(canvas, ctx, snapshot, snapshot.trigger_markers, boundariesRef.current.mk_trig_chart_start_y, boundariesRef);

        let yOffset = boundariesRef.current.event_start_y ;
        for (let i = 0 ; i < snapshot.chart_items.length; i++) {
            let chart_item = snapshot.chart_items[i] ;
            if (chart_item.is_event) {
                yOffset = drawEventChartItem(canvas, ctx, ld, yOffset, boundariesRef, chart_item);
            }
            else {
                yOffset = drawLineChartItem(canvas, ctx, ld, yOffset, boundariesRef, chart_item);
            }
        }

        if (activeDragEventRef.current) {
            drawDragSelection(canvas, ctx, activeDragEventRef);
        }

        if (!activeDragEventRef.current && mouseCursorRef.current) {
            drawVerticalLine(canvas, ctx, latest_data(), mouseCursorRef.current.x, boundariesRef);
        }
    }

    const configureCanvas = (canvas) => {
        const ctx = canvas.getContext('2d');

        canvas.addEventListener('mousedown', (event) => {
            if (event.button !== 0) {
                return
            }

            let ld = latest_data();
            if (!ld || !ld.valid || !ld.active_snapshot) {
                return;
            }

            const rect = canvas.getBoundingClientRect();
            const scaleX = canvas.width / rect.width;
            let dragStartX = (event.clientX - rect.left) * scaleX;
            let dragEndX = dragStartX;
            let dragEvent = {...emptyDragEvent} ;
            dragEvent.drag_start_x = dragStartX ;
            dragEvent.drag_end_x = dragEndX ;
            dragEvent.scale_x = scaleX ;

            let startIndex = findClosestBefore(ld, dragStartX, boundariesRef);
            let endIndex = findClosestAfter(ld, dragEndX, boundariesRef);
            dragEvent.drag_start_index = startIndex ;
            dragEvent.drag_end_index = endIndex ;
            dragEvent.drag_start_time = ld.date_points[startIndex] ;
            dragEvent.drag_end_time = ld.date_points[endIndex] ;
            dragEvent.delta_x = Math.abs(dragEndX - dragStartX) ;
            setActiveDragEvent(dragEvent) ;

            // console.log("Start Drag Event: ", dragEvent, " start index = ", startIndex, " end index = ", endIndex, " dragStartX=", dragStartX, " dragEndX=", dragEndX);

            move(startIndex);
        });

        canvas.addEventListener('mousemove', (event) => {
            let ld = latest_data();
            if (!ld || !ld.valid || !ld.active_snapshot) {
                return;
            }

            if (activeDragEventRef.current) {
                const rect = canvas.getBoundingClientRect();
                const scaleX = canvas.width / rect.width;
                let dragEndX = (event.clientX - rect.left) * scaleX;

                let updatedDragEvent = {...activeDragEventRef.current} ;
                updatedDragEvent.drag_end_x = dragEndX ;
                let ld = latest_data();
                let endIndex = findClosestAfter(ld, dragEndX, boundariesRef);
                updatedDragEvent.drag_end_index = endIndex ;
                updatedDragEvent.drag_end_time = ld.date_points[endIndex] ;
                updatedDragEvent.delta_x = Math.abs(dragEndX - updatedDragEvent.drag_start_x) ;
                setActiveDragEvent(updatedDragEvent) ;

                draw(canvas, ctx, latest_data());
            }
            else {
                const rect = canvas.getBoundingClientRect();
                const scaleX = canvas.width / rect.width;
                const scaleY = canvas.height / rect.height;
                let mx = (event.clientX - rect.left) * scaleX;
                let my = (event.clientY - rect.top) * scaleY;
                if (mx < boundariesRef.current.left_pad || mx > boundariesRef.current.width - boundariesRef.current.right_pad) {
                    if (mouseCursorRef.current) {
                        draw(canvas, ctx, latest_data());
                    }
                    setMouseCursor(null);
                }
                else {
                    setMouseCursor({x: mx, y: my});
                    draw(canvas, ctx, latest_data());
                }
            }
        });

        canvas.addEventListener('mouseup', (event) => {
            if (event.button !== 0) {
                return;
            }

            let ld = latest_data();
            if (!ld || !ld.valid || !ld.active_snapshot) {
                return;
            }

            if (activeDragEventRef.current) {
                if (activeDragEventRef.current.delta_x < 10) {
                    setActiveDragEvent(null) ;
                    draw(canvas, ctx, latest_data());
                    return;
                }

                let startIndex = activeDragEventRef.current.drag_start_index;
                let endIndex = activeDragEventRef.current.drag_end_index ;

                if (startIndex === null || endIndex === null) {
                    return;
                }

                if (startIndex > endIndex) {
                    let temp = startIndex ;
                    startIndex = endIndex ;
                    endIndex = temp ;
                }

                // Log the start and end times of the selected range

                setActiveDragEvent(null) ;
                generateDataSnapshot(targetDataRef.current, startIndex, endIndex);
                redrawCanvas();
            }
        });

        canvas.addEventListener('mouseleave', () => {
            let ld = latest_data();
            if (!ld || !ld.valid || !ld.active_snapshot) {
                return;
            }

            // popup.style.display = 'none';
            setMouseCursor(null);
            setActiveDragEvent(null) ;
            draw(canvas, ctx, latest_data());
        });

        canvas.addEventListener('contextmenu', (event) => {
            if (event.button !== 2) {
                return;
            }

            let ld = latest_data();
            if (!ld || !ld.valid || !ld.active_snapshot) {
                return;
            }

            event.preventDefault();
            if (!targetDataRef.current) {
                return ;
            }

            if (targetDataRef.current.snapshots.length > 1) {
                let newData = {...targetDataRef.current} ;
                newData.snapshots.pop();
                newData.active_snapshot = newData.snapshots[newData.snapshots.length - 1];
                setTargetData(newData) ;
                redrawCanvas();

            }
            else if (targetDataRef.current.snapshots.length === 1) {
                let newData = {...targetDataRef.current} ;
                newData.active_snapshot = newData.snapshots[0];
                setTargetData(newData) ;
                redrawCanvas();
            }
        });
    }

    const setItemVisibility = (key, visibility) => {
        let ld = latest_data();
        let item = ld.active_snapshot.chart_items.find(a => a.key === key);
        if (item) {
            item.show = visibility;
            redrawCanvas();
        }
    }

    const toggleItemShading = (key) => {
        let ld = latest_data();
        let item = ld.active_snapshot.chart_items.find(a => a.key === key);
        if (item) {
            item.shade_back = !item.shade_back;
            redrawCanvas();
        }
    }


    const removeTimelineItem = (key) => {
        let ld = latest_data();
        let item = ld.active_snapshot.chart_items.find(a => a.key === key);
        if (item) {
            console.log("Removing item from timeline ", key, ld.active_snapshot.chart_items);
            console.log("Removing item from timeline in view spec ", key, selectedViewSpecRef.current);
            // Need to remove this item from the view spec
            let vs = {...selectedViewSpecRef.current} ;
            let vsitem = vs.items.findIndex(a => a.key === key) ;
            if (vsitem !== -1) {
                vs.items.splice(vsitem, 1) ;
                setSelectedViewSpec(vs) ;

                ld.active_snapshot.chart_items = ld.active_snapshot.chart_items.filter(a => a.key !== key) ;
                redrawCanvas();
            }
            else {
                console.log("Could not find item to remove in view spec ", key, vs.items);
            }
        }
        else {
            console.log("Could not find item to remove in timeline ", key, ld.active_snapshot.chart_items);
        }
    }

    const showAllItems = () => {
        let ld = latest_data();
        ld.active_snapshot.chart_items.forEach(a => a.show = true);
        redrawCanvas();
    }

    const onSelectViewSpec = async (vs) => {
        setSelectedViewSpec(vs) ;
        setSpecificationName(vs?.name || "") ;
        await SessionState.put('selected_tl_view_spec', vs?.value) ;
    }

    const setDisplay = (groupOn) => {
        if (groupOn === dataTypeSelection) {
            groupOn = 0 ; // clear
        }

        setDataTypeSelection(groupOn) ;
    }

    const toggleADCOnOff = (adc_item, adc_key, onOff) => {
        let vs = {...selectedViewSpec} ;
        console.log("TOGGLE ADC ON OFF : ", adc_item, adc_key, onOff) ;
        console.log("TOGGLE vs : ", vs) ;
        if (!adc_item) {
            return ;
        }

        if (!adc_item.inViewSpec) {
            // Adding an ADC item
            let new_item = {
                key : adc_item.key,
                config : {
                    show : true,
                    shade_back : false,
                    bg_color : 'rgb(255,255,255)',
                    ln_color : 'rgb(232,231,231)'
                }
            }

            vs.items.push(new_item) ;
            setSelectedViewSpec(vs) ;
            redrawCanvas();
        }
    }

    const onSelectAnalogueItem = (analogue_item) => {
        let new_item = {
            key: analogue_item.key,
            config : {
                show: true,
                shade_back: analogue_item.shade_back || false,
                bg_color: analogue_item.bg_color || 'rgb(255,255,255)',
                ln_color: analogue_item.ln_color || 'rgb(0,0,0)'
            }
        }

        let vs = {...selectedViewSpec} ;
        vs.items.push(new_item) ;
        setSelectedViewSpec(vs) ;
        redrawCanvas();
    }

    const removeItemFromViewSpecList = (key, targetList) => {
        let vs = {...selectedViewSpec} ;
        vs[targetList] = vs[targetList].filter(a => a.key !== key) ;
        setSelectedViewSpec(vs) ;
    }

    const onRemoveAnalogueItem = (item) => {
        console.log("REMOVE ANALOGUE ITEM : ", item) ;
        if (!item || !item.key) {
            return ;
        }

        removeTimelineItem(item.key) ;
    }

    const onRemoveMiscDataItem = (item) => {
        console.log("REMOVE MISC DATA ITEM : ", item) ;
        if (!item || !item.key) {
            return ;
        }

        removeTimelineItem(item.key) ;
    }

    const onAddMiscDataItem = (item) => {
        let ld = latest_data();
        // console.log("[MISC] SELECT MISC DATA ITEM : ", item) ;
        // console.log("[MISC] CURRENT VIEW SPEC : ", selectedViewSpecRef.current) ;
        // console.log("[MISC] CURRENT SNAPSHOT : ", ld ) ;

        let new_item = {
            key: item.key,
            config : {
                show: true,
                shade_back: item.shade_back ?? false,
                bg_color: item.bg_color ?? 'rgb(255,255,255)',
                ln_color: item.ln_color ?? 'rgb(0,0,0)'
            }
        }

        if (item.type === 'LOOKUP') {
            if (item.lookup) {
                new_item.config.lookups = {}
            }

            new_item.config.lookups[`${ld.io_spec.version}`] = item.lookup_selected
        }

        console.log("[MISC] Adding new item : ", new_item) ;

        let vs = {...selectedViewSpec} ;
        vs.items.push(new_item) ;
        setSelectedViewSpec(vs) ;
        redrawCanvas();
    }


    const onUpdateMiscDataItem = (item) => {
        let ld = latest_data();
        // console.log("[MISC] UPDATE -> SELECT MISC DATA ITEM : ", item) ;
        // console.log("[MISC] UPDATE -> CURRENT VIEW SPEC : ", selectedViewSpecRef.current) ;
        // console.log("[MISC] UPDATE -> CURRENT SNAPSHOT : ", ld ) ;

        let itemspec = selectedViewSpecRef.current.items.find(a => a.key === item.key) ;
        if (!itemspec) {
            return ;
        }

        if (item.type === 'LOOKUP') {
            let new_item = {...itemspec} ;

            if (!new_item?.config?.lookups?.[`${ld.io_spec.version}`]) {
                new_item.config.lookups = {}
            }

            new_item.config.lookups[`${ld.io_spec.version}`] = item.lookup_selected
            let vs = {...selectedViewSpec} ;
            let ix = vs.items.findIndex(a => a.key === item.key) ;
            if (ix !== -1) {
                vs.items[ix] = new_item ;
                setSelectedViewSpec(vs) ;
                redrawCanvas();
            }
        }
    }

    const moveItemInDirection = (event, itemAtIndex, direction) => {
        // console.log("MOVE ", event, " itemAtIndex=", itemAtIndex, " direction=", direction);
        // console.log("SNAPSHOT ", targetDataRef.current.active_snapshot.chart_items);
        let itemList = targetDataRef.current.active_snapshot.chart_items
        if (direction === -1) {
            // Move the element at itemAtIndex before the previous (non-hidden) element
            for (let i = itemAtIndex - 1; i >= 0; i--) {
                if (itemList[i].show) {
                    let temp = itemList[i];
                    itemList[i] = itemList[itemAtIndex];
                    itemList[itemAtIndex] = temp;
                    break;
                }
            }

        }
        else {
            // Now move the element at itemAtIndex after the next (non-hidden) element
            for (let i = itemAtIndex + 1; i < itemList.length; i++) {
                if (itemList[i].show) {
                    let temp = itemList[i];
                    itemList[i] = itemList[itemAtIndex];
                    itemList[itemAtIndex] = temp;
                    break;
                }
            }
        }

        redrawCanvas();
    }

    return (
        <div className="lv-time">

            <div>
                <button className={"button-small button-refresh"} onClick={showAllItems}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>expand_all</span>Show Hidden Items</button>
                <button className={"button-small button-info"} onClick={() => setShowDataSelect(!showDataSelect)}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>edit</span>Modify Data Selection</button>
                <div style={{display: 'inline-block', marginLeft: '10px'}}>
                    <label style={{display : 'inline-block', marginRight: '10px'}}>View Configuration</label>
                    <SelectDropdown
                        values={viewSpecificationRef.current.views}
                        selected={selectedView}
                        onSelectChanged={(s) => onSelectViewSpec(s)}
                        title="Select Configuration"
                        showDefault={false}/>
                </div>

            </div>

            {(showDataSelect) &&
                <div>
                    <div style={{paddingBottom: '10px', paddingTop: '20px'}}>
                        <div style={{display: 'inline-block', marginLeft: '10px', padding: '10px'}}>
                            <label style={{display : 'inline-block', marginRight: '10px'}}>Configuration Name</label>
                            <input type="text"
                                   style={{width: '300px', marginRight: '10px'}}
                                   placeholder="selection name"
                                   onChange={(e) => setSpecificationName(e.target.value)}
                                   value={specificationName}
                            />
                            {(selectedViewSpec) && <button className={"button-small button-info"} onClick={() => saveConfiguration('copy')}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>save</span>Save as new Configuration</button>}
                            {(selectedViewSpec) && <button className={"button-small button-info"} onClick={() => saveConfiguration('update')}><span className="material-symbols-outlined" style={{verticalAlign: '-7px'}}>save</span>Update Configuration</button>}


                            {(authorisation.isAdmin) &&
                                <div style={{marginLeft: '130px', paddingTop: '10px'}}>
                                    <input type="checkbox" style={{marginRight: '10px'}} checked={isGlobalSpec} onChange={() => setIsGlobalSpec(!isGlobalSpec)} />
                                    <small><b><i>Make available to all users</i></b></small>
                                </div>
                            }


                        </div>
                        <div>
                            <small style={{
                                display: 'inline-block',
                                marginRight: '10px',
                                marginLeft: '10px',
                                fontWeight: 'bold'
                            }}>Choose the data you want to see</small>
                            <div
                                className={dataTypeSelection === 1 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(1)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>timeline</span>Analogue Values
                            </div>
                            <div
                                className={dataTypeSelection === 2 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(2)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>arrows_input</span>IO INPUTS
                            </div>
                            <div
                                className={dataTypeSelection === 6 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(6)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>arrows_output</span>IO OUTPUTS
                            </div>
                            <div
                                className={dataTypeSelection === 3 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(3)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>list</span>Misc Data/Lookups
                            </div>
                            <div
                                className={dataTypeSelection === 4 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(4)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>data_alert</span>L475 Logs
                            </div>
                            <div
                                className={dataTypeSelection === 5 ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => setDisplay(5)}><span className="material-symbols-outlined" style={{
                                marginRight: '10px',
                                verticalAlign: '-6px'
                            }}>data_alert</span>F301 Logs
                            </div>

                        </div>

                    </div>

                    {(dataTypeSelection === 1) &&
                        <AnalogueItemSelector io_spec={liftSpecificationRef.current} viewSpec={selectedViewSpec} onUpdate={onSelectAnalogueItem} onRemove={onRemoveAnalogueItem}/>
                    }

                    {(dataTypeSelection === 2) &&
                        <DigitalOnOffItemSelector io_spec={liftSpecificationRef.current} viewSpec={selectedViewSpec} onItemToggle={toggleADCOnOff}/>
                    }

                    {(dataTypeSelection === 6) &&
                        <DigitalOutputSelector io_spec={liftSpecificationRef.current} viewSpec={selectedViewSpec} onItemToggle={toggleADCOnOff}/>
                    }

                    {(dataTypeSelection === 3) &&
                        <GeneralDatatemSelector io_spec={liftSpecificationRef.current} viewSpec={selectedViewSpec} onAddItem={onAddMiscDataItem} onUpdate={onUpdateMiscDataItem} onRemove={onRemoveMiscDataItem}/>
                    }

                    {(dataTypeSelection === 4) &&
                        <L475LogItemSelector io_spec={liftSpecificationRef.current}  active_data_set={dataViewSet} viewSpec={selectedViewSpec} onAddItem={onAddMiscDataItem} onUpdate={onUpdateMiscDataItem} onRemove={onRemoveMiscDataItem}/>
                    }

                    {(dataTypeSelection === 5) &&
                        <F301LogItemSelector io_spec={liftSpecificationRef.current} active_data_set={dataViewSet}   viewSpec={selectedViewSpec} onAddItem={onAddMiscDataItem} onUpdate={onUpdateMiscDataItem} onRemove={onRemoveMiscDataItem}/>
                    }

                </div>
            }

            <div style={{display: 'flex', width: '100%', paddingTop: '20px'}}>
                <div style={{flex: '0 0 250px'}}>
                    {(targetDataRef.current && boundariesRef.current && targetDataRef.current.active_snapshot && targetDataRef.current.valid) &&
                        <>
                            <div style={{fontSize: 'small', color : 'blue', height: `${boundariesRef.current ? boundariesRef.current.top_pad : 0}px`}}>Data Distribution</div>

                            {targetDataRef.current.active_snapshot.comms_markers.map((marker, index) => {

                                let h = boundariesRef.current.mk_item_h ;
                                let ac = marker.instances ? marker.instances.length : 0;
                                return (
                                    <div key={index} style={{height: `${h}px`, fontSize: 'small', borderTop: '0.25px solid darkgray'}}>
                                        <b>{marker.label}<small> [<span style={{color:'red'}}>{ac}</span>]</small></b>
                                        <br/>
                                        <span className="material-symbols-outlined" style={{fontSize: '24px', color: `darkgray`, cursor: 'pointer'}} onClick={() => {}}>cell_tower</span>
                                        {/*<span className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '24px', color: `blue`, cursor: 'pointer'}} onClick={() => toggleTimelineVisibility(marker.key)}>hide</span>*/}
                                    </div>
                                )
                            })}

                            {targetDataRef.current.active_snapshot.trigger_markers.map((marker, index) => {
                                let h = boundariesRef.current.mk_item_h ;
                                let ac = marker.instances ? marker.instances.length : 0;
                                return (
                                    <div key={index} style={{height: `${h}px`, fontSize: 'small', borderTop: '0.25px solid darkgray'}}>
                                        <b>{marker.label}<small> [<span style={{color:'red'}}>{ac}</span>]</small></b>
                                        <br/>
                                        <span className="material-symbols-outlined" style={{fontSize: '24px', color: `red`, cursor: 'pointer'}} onClick={() => {}}>report</span>
                                        {/*<span className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '24px', color: `blue`, cursor: 'pointer'}} onClick={() => toggleTimelineVisibility(marker.key)}>hide</span>*/}
                                    </div>
                                )
                            })}

                            {(targetDataRef.current.view_spec.show_journeys) &&
                                <div key={"jrny"} style={{height: `${boundariesRef.current.journey_h}px`, fontSize: 'small', borderTop: '0.25px solid darkgray'}}>
                                    <div><b>Journeys<small> [<span style={{color:'red'}}>{targetDataRef.current.active_snapshot.journeys.instances.length}</span>]</small></b></div>
                                    <div style={{textAlign: 'left', paddingTop: '10px'}} >
                                        <span className="material-symbols-outlined" style={{fontSize: '18px', color: `green`, cursor: 'pointer'}} onClick={() => {}}>format_color_fill</span>
                                        <span className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => {}}>hide</span>
                                    </div>
                                </div>
                            }

                            {targetDataRef.current.active_snapshot.chart_items.map((event, index) => {
                                let is_last = index === targetDataRef.current.active_snapshot.chart_items.length - 1 ;
                                if (event.is_event) {
                                    if (!event.show) {
                                        return null;
                                    }

                                    let trip = null
                                    let cval = "" ;
                                    if (event.type === "ADC" || event.type === "OUT") {
                                        if (event.adc_spec && dataViewSet?.current) {
                                            let d = dataViewSet.current ;
                                            switch (event.adc_spec.adc) {
                                                case "A" :
                                                    cval = (d.adc_bits_a[event.adc_spec.bit - 1] === "1") ? event.adc_spec.hl : event.adc_spec.ll ;
                                                    trip = d.adc_bits_a[event.adc_spec.bit - 1] === event.adc_spec.trip ;
                                                    break ;
                                                case "B" :
                                                    cval = (d.adc_bits_b[event.adc_spec.bit - 1] === "1") ? event.adc_spec.hl : event.adc_spec.ll ;
                                                    trip = d.adc_bits_b[event.adc_spec.bit - 1] === event.adc_spec.trip ;
                                                    break ;
                                                case "C" :
                                                    cval = (d.adc_bits_c[event.adc_spec.bit - 1] === "1") ? event.adc_spec.hl : event.adc_spec.ll ;
                                                    trip = d.adc_bits_c[event.adc_spec.bit - 1] === event.adc_spec.trip ;
                                                    break ;
                                                case "O" :
                                                    let bval = get_bit(d.o_outputs, event.adc_spec.bit - 1) ;
                                                    cval = (bval === 1) ? event.adc_spec.hl : event.adc_spec.ll ;
                                                    trip = bval === event.adc_spec.trip ;
                                                    break ;
                                                default: cval = "?"; break ;
                                            }
                                        }
                                        else {
                                            console.log("No ADC Spec for item : ", event) ;
                                        }
                                    }
                                    else if (event.type === "LOOKUP") {
                                        let val = dataViewSet?.current[event.key]
                                        if (val !== undefined) {
                                            let lookupItem = event.lookup[val] ;
                                            cval = lookupItem ? lookupItem.label : "" ;
                                        }
                                    }
                                    else {
                                        cval = dataViewSet?.current[event.key] || "";
                                    }

                                    // This looks really odd I admit but it's a way to try and align the labels and the chart items (seems to work reasonably well)
                                    let h = boundariesRef.current.tl_item_h - (2 - 0.1 * index);
                                    let ac = event.instances ? event.instances.length : 0;
                                    return (
                                        <div key={index} style={{height: `${h}px`, fontSize: 'small', borderTop: '0.15px solid darkgray'}}>
                                            {!event.show_item_count && <div><b>{event.label}</b></div>}
                                            {event.show_item_count && <div><b>{event.label}<small> [<span style={{color:'red'}}>{ac}</span>]</small></b></div>}
                                            <div style={{fontSize: 'smaller', paddingTop: '4px', textAlign: 'right'}}>{cval}</div>

                                            <div style={{paddingTop: '10px'}} >
                                                <div className="material-symbols-outlined" style={{fontSize: '18px', color: `green`, cursor: 'pointer'}} onClick={() => toggleItemShading(event.key)}>format_color_fill</div>
                                                {index > 0 && <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => moveItemInDirection(event, index, -1)}>arrow_upward</div>}
                                                {!is_last && <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => moveItemInDirection(event, index, 1)}>arrow_downward</div>}
                                                <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => setItemVisibility(event.key, false)}>hide</div>
                                                <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `red`, cursor: 'pointer'}} onClick={() => removeTimelineItem(event.key)}>cancel</div>
                                                {trip && <div className="material-symbols-outlined" style={{paddingLeft: '30px',fontSize: '18px', color: `blue`, cursor: 'pointer', float: 'right'}} >priority</div>}
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    if (!event.show) {
                                        return null;
                                    }

                                    let dpv = dataViewSet?.current[event.key] || "";

                                    let h = boundariesRef.current.ln_chart_item_h ;
                                    let minv = event.min ;
                                    let maxv = event.max ;
                                    return (
                                        <div key={index} style={{height: `${h}px`, fontSize: 'small', borderTop: '0.25px solid darkgray'}}>
                                            <b>{event.label}</b>
                                            <br/>
                                            <small>Min : {minv}</small><br/>
                                            <small>Max : {maxv}</small>
                                            <div style={{textAlign: 'left', paddingTop: '20px'}} >
                                                <span className="material-symbols-outlined" style={{fontSize: '18px', color: `green`, cursor: 'pointer'}} onClick={() => toggleItemShading(event.key)}>format_color_fill</span>
                                                {index > 0 && <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => moveItemInDirection(event, index, -1)}>arrow_upward</div>}
                                                {!is_last && <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => moveItemInDirection(event, index, 1)}>arrow_downward</div>}
                                                <span className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `blue`, cursor: 'pointer'}} onClick={() => setItemVisibility(event.key, false)}>hide</span>
                                                <div className="material-symbols-outlined" style={{paddingLeft: '10px',fontSize: '18px', color: `red`, cursor: 'pointer'}} onClick={() => removeTimelineItem(event.key)}>cancel</div>

                                                <span style={{float: 'right'}}>{dpv}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                        </>
                    }

                </div>
                <div style={{flex: '1', overflow: 'hidden'}}><canvas ref={canvasRef} id="timeline_canvas"></canvas></div>
            </div>

        </div>
    )

}

