import moment from "moment";


export const ChartDataMapper = (timeline_data) => {
    console.log("Creating Timeline from ", timeline_data) ;

    let result = {
        device_id : timeline_data.lift.device.device_id,
        earliest_data : timeline_data.dataset.range_start,
        latest_data : timeline_data.dataset.range_end,
        num_data_points : timeline_data.dataset.total_records,
        target_data : {},
        ts: [],
        analogue_count : 0,
        event_count : 0
    }

    for (let vsi of timeline_data.view_spec.items) {
        let key = vsi.key ;
        let target = timeline_data.io_spec.data_items[key] ;
        if (target) {
            result.analogue_count += (target.type === "ANALOGUE") ? 1 : 0 ;
            result.event_count += (target.type !== "ANALOGUE") ? 1 : 0 ;
            let is_event = target.type !== "ANALOGUE" ;
            result.target_data[key] = {...target, is_event : is_event, data : [], available: true} ;
            if (target.type === "ADC" || target.type === "OUT") {
                result.target_data[key].adc_spec = timeline_data.io_spec.item_adc_map[key];
            }

            if (vsi.config) {
                result.target_data[key].show = vsi.config.show ?? target.show;
                result.target_data[key].shade_back = vsi.config.shade_back ?? target.shade_back;
                result.target_data[key].bg_color = vsi.config.bg_color ?? target.bg_color;
                result.target_data[key].ln_color = vsi.config.ln_color ?? target.ln_color;
            }
        }
        else {
            console.warn("No target found for key : ", key) ;
            result.target_data[key] = {
                key: key,
                label: `N/A : ${key}`,
                type : "UNAVAILABLE",
                available: false,
                show : true,
                shade_back: true,
                bg_color: 'rgba(0,0,0,0.1)',
                is_event: true,
                instances : []
            } ;
        }
    }

    for (let d of timeline_data.dataset.data) {
        // let m = moment.utc(d.t_rtc) ;
        // if (m.isValid()) {
        //     result.ts.push(m.local()) ;
        // }
        // else {
        //     result.ts.push(moment.utc(d.received_at).local()) ;
        // }
        result.ts.push(moment.utc(d.received_at).local()) ;

        for (let vsi of timeline_data.view_spec.items) {
            let key = vsi.key ;
            let target = result.target_data[key] ;
            if (target && target.available) {
                if (target.adc_spec) {
                    let adc = target.adc_spec ;
                    let tripped = false ;

                    switch (adc.adc) {
                        case "A" : tripped = (d.adc_bits_a[adc.bit - 1] === adc.trip) ; break ;
                        case "B" : tripped = (d.adc_bits_b[adc.bit - 1] === adc.trip) ; break ;
                        case "C" : tripped = (d.adc_bits_c[adc.bit - 1] === adc.trip) ; break ;
                        case "O" :
                            let oval = d.o_outputs[adc.bit - 1] ;
                            tripped = (oval === adc.trip) ; break ;
                        default: break ;
                    }

                    result.target_data[key].data.push(tripped);
                }
                else {
                    result.target_data[key].data.push(d[key]) ;
                }
            }
        }
    }

    console.log("Full Mapped Result : ", result) ;
    return result ;
}