import {useEffect, useState} from "react";

const CircleWithText = ({ title, text }) => {
    const [displayText, setDisplayText] = useState("");
    const [floorStyle, setFloorStyle] = useState("lightgrey");

    useEffect(() => {
        if (!text) return;

        switch (text) {
            case "0":
                setDisplayText("B");
                setFloorStyle("green");
                break;
            case "1":
                setDisplayText("T");
                setFloorStyle("green");
                break;
            case "85":
                setDisplayText("-");
                setFloorStyle("blue");
                break;
            case "255":
                setDisplayText("E");
                setFloorStyle("red");
                break;
            default:
                setDisplayText(`${text}`);
                setFloorStyle("green");

        }

    }, [text])


    return (
        <div style={{display: 'inline-block', width: '85px', padding: '5px'}}>
            <div  style={{fontWeight:'bold', fontSize: '12px', textAlign: 'center'}}>{title}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'}}>
                <svg width="55" height="55" viewBox="0 0 55 55">
                    <circle cx="27.5" cy="27.5" r="22" stroke="darkgrey" strokeWidth="3" fill={floorStyle}/>
                    <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="32" fontFamily="Arial" fill="white">
                        {displayText}
                    </text>
                </svg>
            </div>
        </div>
    );
};


const LiftFloorView = ({lift_status}) => {

    const [current_floor, setCurrentFloor] = useState("?");
    const [last_floor, setLastFloor] = useState("?");
    const [ramp_floor, setRampFloor] = useState("?");
    const [encoder_floor, setEncoderFloor] = useState("?");

    useEffect(() => {
        if (!lift_status) return;

        /*
         * Floor value mapping changed going from JSON V1 to JSON V2 (fw v4 -> fw v5)
         * So we have to do a bit of fiddling about to get the values we want to display
         */
        let floors = lift_status.floors;
        if (lift_status.data_version < 5) {
            console.log("V4 floors", floors);

            let c = "" ;
            if (floors.at_bottom_floor) {
                c = "B"
            }
            else if (floors.at_top_floor) {
                c = "T"
            }
            else {
                c = "-"
            }

            setCurrentFloor(c);
        }
        else {
            setCurrentFloor(`${floors.v_current_floor}`);
            setLastFloor(`${floors.v_last_known_floor}`);
            setRampFloor(`${floors.v_ramp_floor}`);
            setEncoderFloor(`${floors.v_encoder_floor}`);
        }

    }, [lift_status])


    return (
        <div style={{marginLeft: '50px'}}>
            <h4>Lift Floor State</h4>
            <CircleWithText text={current_floor} title="Current"/>
            {(lift_status?.data_version >= 5) && <CircleWithText text={last_floor} title="Last Known"/>}
            {(lift_status?.data_version >= 5) && <CircleWithText text={ramp_floor} title="Ramp"/>}
            {(lift_status?.data_version >= 5) && <CircleWithText text={encoder_floor} title="Encoder"/>}
        </div>
    );
}

export default LiftFloorView;