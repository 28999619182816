

export const drawUpArrow = (ctx, x, y, width, height, fill) => {
    ctx.beginPath();
    ctx.moveTo(x, y + height); // Bottom point
    ctx.lineTo(x + width / 2, y); // Top point
    ctx.lineTo(x + width, y + height); // Bottom right point
    ctx.closePath();
    if (fill) {
        ctx.fill();
    } else {
        ctx.stroke();
    }
}

export const drawDownArrow = (ctx, x, y, width, height, fill) => {
    ctx.beginPath();
    ctx.moveTo(x, y); // Top point
    ctx.lineTo(x + width / 2, y + height); // Bottom point
    ctx.lineTo(x + width, y); // Top right point
    ctx.closePath();
    if (fill) {
        ctx.fill();
    } else {
        ctx.stroke();
    }
}

export const drawLeftArrow = (ctx, x, y, width, height, fill) => {
    ctx.beginPath();
    ctx.moveTo(x + width, y); // Right point
    ctx.lineTo(x, y + height / 2); // Middle point
    ctx.lineTo(x + width, y + height); // Bottom right point
    ctx.closePath();
    if (fill) {
        ctx.fill();
    } else {
        ctx.stroke();
    }
}

