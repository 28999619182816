// These values map directly to the io_adc_item_name table in the database

import Helper from "../library/Helper";
import {get_bit} from "../views/live-connect/common_decode";


const get_target_bit_set = (datapoint, source_adc) => {
    switch (source_adc) {
        case "a":
        case "A":
            return datapoint.adc_bits_a
        case "b":
        case "B":
            return datapoint.adc_bits_b
        case "c":
        case "C":
            return datapoint.adc_bits_c
        case "O":
            return datapoint.o_outputs
        default:
            return null
    }
}

export const IODefaultDoorState = () => {
    return {
        valid: false,
        locked: false,
        open: false,
        on_landing: false,
        dss : false,
        hz : false,
        lss : false
    }
}

export const IODoorState = (io_model, datapoint) => {
    let result = IODefaultDoorState() ;
    if (!io_model || !datapoint) {
        return result
    }

    let dss = false
    let hz = false
    let lss = false

    if (io_model.fw_revision >= 3) {
        let dss1 = IOGetRaw(io_model, datapoint, "DOOR1_DSS_BIT") ;
        let dss2 = IOGetRaw(io_model, datapoint, "DOOR2_DSS_BIT") ;
        hz = IOGetRaw(io_model, datapoint, "DOOR_HZ_BIT") ;
        lss = IOGetRaw(io_model, datapoint, "DOOR_LSS_BIT") ;
        dss = dss1 && dss2 ;
    }
    else {
        dss = IOGetRaw(io_model, datapoint, "DOOR_DSS_BIT") ;
        hz = IOGetRaw(io_model, datapoint, "DOOR_HZ_BIT") ;
        lss = IOGetRaw(io_model, datapoint, "DOOR_LSS_BIT") ;
    }


    result.on_landing = !lss ;
    result.locked = lss ;
    result.valid = true ;
    result.dss = dss ;
    result.hz = hz ;
    result.lss = lss ;
    // result.open = !dss && !hz && !lss ;
    result.open = !dss ;

    return result ;
}

export const IOGetIfNoPriorChainItem = (io_model, datapoint, io_item) => {
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // console.log("IOGetIfNoPriorChainItem: io_item = ", io_item)
    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    for (let sc of io_model.primary_safety) {
        // console.log("IOGetIfNoPriorChainItem: check = ", sc === io_item, sc, io_item)
        if (sc === io_item) {
            // The bit we're looking for so just run the normal set value
            let rx = IOGet(io_model, datapoint, io_item);
            // console.log("IOGetIfNoPriorChainItem: Found item in primary safety chain = ", io_item, rx)
            return rx ;
        }

        let vx = IOGet(io_model, datapoint, sc);
        if (vx) {
            // console.log("IOGetIfNoPriorChainItem: Found preceding item in primary safety chain = ", sc, ", io_item = ", io_item)
            return false ;
        }
    }

    return false ;
}

export const IOGet = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.target_io.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return ""
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    let bit_value = bit_set[adc_set.bit - 1]
    // console.log("bit_value check = ", io_item, bit_value, adc_set.trip, bit_value === adc_set.trip)
    return (bit_value === adc_set.trip)
}

export const IOGetRaw = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return false
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return false
    }


    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return false
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1] === '1'
}

export const IOGetRawValue = (io_model, datapoint, io_item) => {
    // console.log("IOGet: io_model = ", io_model, ", datapoint = ", datapoint, ", io_item = ", io_item)

    // make sure we have all the data we need
    if (!io_model || !datapoint || !io_item) {
        return "?"
    }

    // We've already pick the IO definition in advance (io_model) so we don't need to filter down any further
    // console.log("io_model.item_adc_map = ", io_model.item_adc_map)
    let adc_set = io_model.item_adc_map[io_item]
    // console.log("ADC Set = ", adc_set, io_item)

    if (!adc_set) {
        return "?"
    }

    // Now we have the adc_set, we need to find the value of the bit in the datapoint
    let bit_set = get_target_bit_set(datapoint, adc_set.adc)
    // console.log("bit_set = ", bit_set)
    if (!bit_set) {
        return "?"
    }

    if (adc_set.adc === "O") {
        let raw = bit_set & (1 << (adc_set.bit - 1))
        return raw ? "1" : "0"
    }

    // Now we have the bit_set, we need to find the value of the bit in the datapoint
    return bit_set[adc_set.bit - 1]
}


export const IODefaultLiftState = () => {
    return {
        is_hb: false,
        door_state: IODefaultDoorState(),
        occupied: false,
        main_safety: {active: false, item: null},
        up_safety: {active: false, item: null},
        down_safety: {active: false, item: null},
        check_dir_safety: false,
        angle: {ok: true, value: 0.0, left: false, right: false},
        weight: 0,
        person_in_lift: false,
        height: 0.0,
        current_floor: null,
        at_bottom_floor: false,
        at_top_floor: false,
        number_of_floors: 0,
        remote_up: false,
        remote_down: false,
        cop_up: false,
        cop_down: false,
        cop_mid: false,
        overloaded: false,
        key_switch: false,
        emergency_stop: false,
        balance1: false,
        balance2: false,
        charger_status: 0,
        wifi_connect_state: 0,
        battery_voltage: 0.0,

        // Top Limits
        top_flim: false,
        top_obst1: false,
        top_obst2: false,
        top_obst3: false,
        top_obst4: false,

        // Bottom Limits
        bot_flim: false,
        bot_lim1: false,
        bot_lim2: false,
        bot_lim3: false,
        bot_lim4: false,
        bot_lim5: false,
        bot_lim6: false,
        bot_lim7: false,

        // Light guards
        light_guard_front: false,
        light_guard_rear: false,

        rol3_bit: false,
        rol2_bit: false,
        prly_pwr_det_bit: false,
        drly_pwr_det_bit: false,

        adc_mapping_available: false,
        adc_a_map: [],
        adc_b_map: [],
        adc_c_map: [],
        output_map: [],
        groups : [],

        l475_runtime_ms : 0,
        f301_runtime_ms : 0,
        rtc : 0,
        last_correction_ms : 0,

        floors : {
            is_multi_floor : false,
            number_of_floors : 0,
            current_floor : null,
            at_bottom_floor : false,
            at_top_floor : false,
            last_floor : null,
        },

        gate_data : []
    }
}

function get_floor_desc(floor, is_v5 = true) {
    // "Software identified floor number. Calculated from ramp and encoder floor values
    // No floor = 0x55
    // Floor error = 0xFF
    // Bottom floor = 0
    // Top floor = 1
    // Mid floor 1 = 2
    // Mid floor 2 = 3 etc."

    if (is_v5) {
        switch (floor) {
            case 0 :
                return "Bottom Floor";
            case 1 :
                return "Top Floor";
            case 0x55 :
                return "No Floor";
            case 0xff :
                return "Floor Error";
            default :
                return `Mid Floor ${floor || ""}`;
        }
    }
    else {
        switch (floor) {
            case 1 :
                return "Bottom Floor";
            case 3 :
                return "Top Floor";
            default :
                return `Unknown Floor ${floor || ""}`;
        }
    }

}


export const IOGetLiftState = (io_model, datapoint, gate_data, floor_data, device) => {
    // console.log("IOGetLiftState: io_model = ", io_model, ", datapoint = ", datapoint, ", device = ", device);
    let result = IODefaultLiftState() ;

    // console.log("IO Model = ", io_model)
    if (datapoint && io_model) {
        let floor_base = floor_data[datapoint.device_data_id] || []

        result.data_version = datapoint.fw_version
        result.device_data_id = datapoint.device_data_id
        result.door_state = IODoorState(io_model, datapoint)
        result.gate_data = gate_data[datapoint.device_data_id] || []
        result.floor_data = floor_data[datapoint.device_data_id] || []
        result.cop_status = datapoint.o_cop_status
        result.is_multi_floor = false
        result.has_gates = false

        result.f301_log_raw = datapoint.s_f301_sr ;
        result.l475_log_raw = datapoint.s_f475_sr ;

        let f301 = io_model?.data_items["s_f301_sr"]?.lookup ?? {};
        let l475 = io_model?.data_items["s_f475_sr"]?.lookup ?? {};
        result.f301_log_desc = f301[datapoint.s_f301_sr]?.label ?? "?"
        result.l475_log_desc = l475[datapoint.s_f475_sr]?.label ?? "?"
        result.f301_log_key = f301[datapoint.s_f301_sr]?.code ?? "LOG_NONE"
        result.l475_log_key = l475[datapoint.s_f475_sr]?.code ?? "LOG_NONE"


        let angle = datapoint.l_lift_angle.toFixed(4) ;
        if (angle >= 0.3) {
            result.angle = {ok : false, value: angle, left : false, right: true}
        }
        else if (angle <= -0.3) {
            result.angle = {ok : false, value: angle, left : true, right: false}
        }
        else {
            result.angle = {ok : true, value: angle, left : false, right: false}
        }

        result.last_journey = {
            direction : (datapoint.j_m1_enc_count_start < datapoint.j_m1_enc_count_end) ? "up" : "down",
            distance : (datapoint.j_m2_enc_count * 0.0174) / 1000.0,
        }

        result.key_switch = IOGet(io_model, datapoint, "KEYSWITCH_BIT")
        result.emergency_stop = IOGetIfNoPriorChainItem(io_model, datapoint, "SAFETY_BIT")


        result.overloaded = IOGet(io_model, datapoint, "OVRL_BIT")
        result.cop_up = IOGet(io_model, datapoint, "UP_CAB_BIT")
        result.cop_down = IOGet(io_model, datapoint, "DOWN_CAB_BIT")
        result.cop_mid = IOGet(io_model, datapoint, "MID_CAB_BIT")
        result.remote_up = IOGet(io_model, datapoint, "TOG_UP_BIT")
        result.remote_down = IOGet(io_model, datapoint, "TOG_DOWN_BIT")

        result.weight = datapoint.k_cur_pot_read
        result.charger_status = datapoint.h_charger_status
        result.charger_error = datapoint.h_charger_error
        result.battery_voltage = datapoint.v_bat_voltage_mv / 1000.0
        result.top_flim = IOGet(io_model, datapoint, "TOP_FLIM_BIT")
        result.top_obst1 = IOGet(io_model, datapoint, "TOP_OBST1_BIT")
        result.top_obst2 = IOGet(io_model, datapoint, "TOP_OBST2_BIT")
        result.top_obst3 = IOGet(io_model, datapoint, "TOP_OBST3_BIT")
        result.top_obst4 = IOGet(io_model, datapoint, "TOP_OBST4_BIT")

        result.bot_flim = IOGet(io_model, datapoint, "BOT_FLIM_BIT")
        result.bot_lim1 = IOGet(io_model, datapoint, "BOT_LIM1_BIT")
        result.bot_lim2 = IOGet(io_model, datapoint, "BOT_LIM2_BIT")
        result.bot_lim3 = IOGet(io_model, datapoint, "BOT_LIM3_BIT")
        result.bot_lim4 = IOGet(io_model, datapoint, "BOT_LIM4_BIT")
        result.bot_lim5 = IOGet(io_model, datapoint, "BOT_LIM5_BIT")
        result.bot_lim6 = IOGet(io_model, datapoint, "BOT_LIM6_BIT")
        result.bot_lim7 = IOGet(io_model, datapoint, "BOT_LIM7_BIT")

        result.light_guard_front = IOGetIfNoPriorChainItem(io_model, datapoint, "LIGHT_GUARD_FRONT_BIT")
        result.light_guard_rear = IOGetIfNoPriorChainItem(io_model, datapoint, "LIGHT_GUARD_REAR_BIT")

        result.rol3_bit = IOGet(io_model, datapoint, "ROL3_BIT")
        result.rol2_bit = IOGet(io_model, datapoint, "ROL2_BIT")
        result.prly_pwr_det_bit = IOGet(io_model, datapoint, "PRLY_PWR_DET_BIT")
        result.drly_pwr_det_bit = IOGet(io_model, datapoint, "DRLY_PWR_DET_BIT")
        result.check_dir_safety = result.door_state.lss ;

        result.l475_runtime_ms = datapoint.t_l475_runtime_ms
        result.f301_runtime_ms = datapoint.t_f301_runtime_ms
        result.rtc = datapoint.t_rtc
        result.last_correction_ms = datapoint.t_last_correction_ms

        result.fa_smoke_alarm_active = datapoint.fa_smoke_alarm_active
        result.fa_smoke_alarm_finish_travel = datapoint.fa_smoke_alarm_finish_travel

        result.b_env = datapoint.b_env
        result.b_msg_fmt = datapoint.b_msg_fmt
        result.b_tx_type = datapoint.b_tx_type

        if (datapoint.fw_version < 3) {
            result.is_hb = datapoint.g_hb === 1
            result.data_trigger = datapoint.g_hb
            result.reason = datapoint.g_hb === 1 ? "Heartbeat" : "Data Trigger"

            let top_floor = datapoint.adc_bits_a[1] === "1"
            let bot_floor = datapoint.adc_bits_b[8] === "1"

            result.floors = {
                is_multi_floor: false,
                number_of_floors : 2,  // doesn't support multi-floor
                v_current_floor : top_floor ? 1 : (bot_floor ? 0 : 0x55),
                v_last_known_floor : "",
                v_ramp_floor : "",
                v_encoder_floor : "",
                at_bottom_floor : bot_floor,
                at_top_floor : top_floor,
                ramp_floor : "",
                encoder_floor : "",
            }

            if (Helper.isNullOrUndefined(result.floors.v_current_floor)) {
                let bf = datapoint.adc_bits_b[8] === "1" ? 1 : 0
                let tf = datapoint.adc_bits_a[2] === "1" ? 1 : 0

                if (bf) {
                    result.floors.v_current_floor = 1
                }
                else if (tf) {
                    result.floors.v_current_floor = 3
                }
                else {
                    result.floors.v_current_floor = 0x55
                }
            }

            if (Helper.isNullOrUndefined(result.floors.v_last_known_floor)) {
                result.floors.v_last_known_floor = 0x55 ;
            }

            result.floors.current_floor = get_floor_desc(datapoint.r_current_floor, false)
            result.floors.last_known_floor = get_floor_desc(datapoint.r_last_known_floor, false)
        }
        else if (datapoint.fw_version < 4) {
            result.is_hb = datapoint.g_hb === 1
            result.data_trigger = datapoint.g_hb
            result.reason = datapoint.g_hb === 1 ? "Heartbeat" : "Data Trigger"

            let current_floor = datapoint.r_current_floor
            let top_floor = current_floor === 3
            let bot_floor = current_floor === 1

            result.floors = {
                is_multi_floor: false,
                number_of_floors : 2,   // doesn't support multi-floor
                v_current_floor : (current_floor === 1 || current_floor === 3) ? current_floor : 0x55,
                v_last_known_floor : datapoint.r_last_known_floor,
                v_ramp_floor : "",
                v_encoder_floor : "",
                at_bottom_floor : bot_floor,
                at_top_floor : top_floor,
                ramp_floor : "",
                encoder_floor : "",
                top_floor : floor_base.find(f => f.floor === 3),
                bottom_floor : floor_base.find(f => f.floor === 1)
            }

            result.floors.current_floor = get_floor_desc(datapoint.r_current_floor, false)
            result.floors.last_known_floor = get_floor_desc(datapoint.r_last_known_floor, false)
        }
        else if (datapoint.fw_version < 5) {
            result.is_hb = datapoint.g_hb === 1
            result.data_trigger = datapoint.g_hb
            result.reason = datapoint.g_hb === 1 ? "Heartbeat" : "Data Trigger"

            let current_floor = datapoint.r_current_floor
            let top_floor = current_floor === 3
            let bot_floor = current_floor === 1

            result.floors = {
                is_multi_floor: false,
                number_of_floors : 2,   // doesn't support multi-floor
                v_current_floor : (current_floor === 1 || current_floor === 3) ? current_floor : 0x55,
                v_last_known_floor : datapoint.r_last_known_floor,
                v_ramp_floor : "",
                v_encoder_floor : "",
                at_bottom_floor : bot_floor,
                at_top_floor : top_floor,
                ramp_floor : "",
                encoder_floor : "",
                top_floor : floor_base.find(f => f.floor === 3),
                bottom_floor : floor_base.find(f => f.floor === 1)
            }

            result.floors.current_floor = get_floor_desc(datapoint.r_current_floor, false)
            result.floors.last_known_floor = get_floor_desc(datapoint.r_last_known_floor, false)
        }
        else {
            let mf = IOGet(io_model, datapoint, "SLIDE_IN_4")
            let has_gates = IOGet(io_model, datapoint, "DIP_SWITCH_8")

            result.floors = {
                is_multi_floor: mf,
                number_of_floors : result.floor_data.length,
                v_current_floor : datapoint.r_current_floor,
                v_last_known_floor : datapoint.r_last_known_floor,
                v_ramp_floor : datapoint.r_ramp_floor,
                v_encoder_floor : datapoint.r_encoder_floor,
                at_top_floor: datapoint.r_current_floor === 1,
                at_bottom_floor: datapoint.r_current_floor === 0,
                current_floor : get_floor_desc(datapoint.r_current_floor, true),
                last_known_floor : get_floor_desc(datapoint.r_last_known_floor, true),
                ramp_floor : get_floor_desc(datapoint.r_ramp_floor, true),
                encoder_floor : get_floor_desc(datapoint.r_encoder_floor, true),
                top_floor : floor_base.find(f => f.floor === 1),
                bottom_floor : floor_base.find(f => f.floor === 0),
                mid_floors : floor_base.filter(f => (f.floor >= 2 && f.floor < 0x55))
            }

            switch (datapoint.g_hb) {
                case 0 : result.reason = "Reason Not Set" ; break ;
                case 1 : result.reason = "Startup Data" ; break ;
                case 2 : result.reason = "Standard Data" ; break ;
                case 3 : result.reason = "Heartbeat Data" ; break ;
                case 4 : result.reason = "E-Stop Data" ; break ;
                case 5 : result.reason = "Charger Error Data"; break ;
                case 6 : result.reason = "Lift Run Error Data"; break ;
                case 7 : result.reason = "RS485 Error Data" ; break ;
                default : result.reason = "Unknown" ; break ;
            }

            result.is_multi_floor = mf
            result.has_gates = has_gates
            result.is_hb = datapoint.g_hb === 3
            result.data_trigger = datapoint.g_hb
            result.v_chrg_pwr_state = datapoint.v_chrg_pwr_state
            result.aa_eng_override = datapoint.aa_eng_override
            result.aa_cab_override = datapoint.aa_cab_override
            result.ae_estop_flag = datapoint.ae_estop_flag
            result.fa_smoke_alarm_active = datapoint.fa_smoke_alarm_active
            result.fa_smoke_alarm_finish_travel = datapoint.fa_smoke_alarm_finish_travel
            result.lv_hard_stop_flag = datapoint.lv_hard_stop_flag
            result.lv_finish_travel = datapoint.lv_finish_travel
            result.ms_301_motor_running = datapoint.ms_301_motor_running
            result.pd1_fully_open = datapoint.pd1_fully_open
            result.pd1_fully_closed = datapoint.pd1_fully_closed
            result.pd2_fully_open = datapoint.pd2_fully_open
            result.pd2_fully_closed = datapoint.pd2_fully_closed
        }
    }

    if (device) {
        result.wifi_connect_state = device.wifi_connect_state_id
        result.person_in_lift = result.weight > 0 && result.weight >= (device.high_weight * 0.75)
        result.occupied = result.cop_up || result.cop_down || result.cop_mid
        result.number_of_floors = device.number_of_floors
    }

    // TODO - fill in the rest of the data
    // first start with working out the current floor based on the landing data
    // Need to actual build a map for the adc a,b and c which include whether tripped and the actual item label

    if (io_model && datapoint) {
        result.adc_mapping_available = true

        // Now need to fill in the adc mapping
        for (let a of io_model.adca) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_a[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_a_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.adcb) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_b[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_b_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.adcc) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = datapoint.adc_bits_c[target.bit - 1]
                let safety = io_model.primary_safety.includes(a)

                result.adc_c_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: safety,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        for (let a of io_model.outputs) {
            let target = io_model.item_adc_map[a]
            if (target) {
                let raw = (datapoint.o_outputs & (1 << target.bit)) ;

                result.output_map.push({
                    io : a,
                    adc : target.adc,
                    label: target.io_id,
                    desc : target.desc,
                    value : raw,
                    trip: target.trip,
                    tripped: raw === target.trip,
                    bit: target.bit,
                    safety: false,
                    stxt : (raw === "1") ? target.hl : target.ll,
                    trc : target.trc,
                    sc : target.sc
                })
            }
        }

        // bit 6 of adcc is light timer setting bit 0 and bit 7 of adcc is light timer setting bit 1
        // when bit 0 is 0 and bit 1 is 0 then the light time is 10seconds
        // when bit 0 is 0 and bit 1 is 1 then the light time is 20seconds
        // when bit 0 is 1 and bit 1 is 0 then the light time is 45seconds
        // when bit 0 is 1 and bit 1 is 1 then the light time is 90seconds
        // i need to set a flag on result to indicate the light timer setting
        let bit0 = datapoint.adc_bits_c[5] === '1' ? 0 : 1
        let bit1 = datapoint.adc_bits_c[6] === '1' ? 0 : 1
        let bits = `${bit0}:${bit1}`
        switch (bits) {
            case "0:0":
                result.light_timer = 10
                break
            case "0:1":
                result.light_timer = 20
                break
            case "1:0":
                result.light_timer = 45
                break
            case "1:1":
                result.light_timer = 90
                break
            default:
                result.light_timer = 10
        }

        if (datapoint.fw_version >= 5) {
            let pdbit0 = datapoint.adc_bits_c[0] === '1' ? 0 : 1
            let pdbit1 = datapoint.adc_bits_c[2] === '1' ? 0 : 1
            let pdbit2 = datapoint.adc_bits_c[3] === '1' ? 0 : 1
            let pdbits = `${pdbit2}:${pdbit1}:${pdbit0}`
            switch (pdbits) {
                case "0:0:0": result.power_door_enabled = false ; break ;
                case "0:0:1":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Front, Top Floor Front" ;
                    break ;
                case "0:1:0":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Front, Top Floor Thru" ;
                    break ;
                case "0:1:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Front" ;
                    break ;
                case "1:0:0":
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Rear" ;
                    break ;
                case "1:0:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Rear, Top Floor Front" ;
                    break ;
                case "1:1:0" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Rear, Top Floor Rear" ;
                    break ;
                case "1:1:1" :
                    result.power_door_enabled = true ;
                    result.power_door_type = "Bottom Floor Thru, Top Floor Thru" ;
                    break ;
                default:
                    result.power_door_enabled = false
            }

            let sin1 = datapoint.adc_bits_c[8] === '1'
            let sin2 = datapoint.adc_bits_c[9] === '1'
            let sin3 = datapoint.adc_bits_c[10] === '1'

            // console.log("Push To Run: ", datapoint.adc_bits_c, datapoint.adc_bits_c[9], datapoint.adc_bits_c[10], datapoint.adc_bits_c[11], sin1, sin2, sin3)
            result.push_to_run = sin1 || sin2
            result.multi_floor = sin2 || sin3
        }
        else {
            result.power_door_enabled = false
            result.push_to_run = false
            result.multi_floor = false
        }

        let primary_safety_set = [...io_model.primary_safety]
        let door_safety_set = [...io_model.primary_safety, ...io_model.door_safety]
        let up_safety_set = [...io_model.primary_safety, ...io_model.door_safety, ...io_model.up_safety]
        let down_safety_set = [...io_model.primary_safety, ...io_model.door_safety, ...io_model.down_safety]
        for (let g of io_model.groups) {
            let group = {
                name: g.name,
                desc: g.desc,
                items: []
            }

            for (let item of g.io) {
                let target = io_model.item_adc_map[item]

                if (target) {
                    let raw = IOGetRawValue(io_model, datapoint, item)

                    let door_safety = false
                    let up_safety = false
                    let down_safety = false
                    let safety = io_model.primary_safety.includes(item)
                    if (!safety) {
                        door_safety = io_model.door_safety.includes(item)
                    }

                    if (!safety && !door_safety) {
                        up_safety = io_model.up_safety.includes(item)
                    }

                    if (!safety && !door_safety && !up_safety) {
                        down_safety = io_model.down_safety.includes(item)
                    }

                    let tripped = raw === target.trip
                    let prior_safety = false
                    if (safety || door_safety || up_safety || down_safety) {
                        // This element is part of a safety chain.
                        // As such the trip handling is different as we only indicate tripped if a prior safety element is not tripped
                        let target_safety_set = primary_safety_set
                        if (door_safety) {
                            target_safety_set = door_safety_set
                        }
                        else if (up_safety) {
                            target_safety_set = up_safety_set
                        }
                        else if (down_safety) {
                            target_safety_set = down_safety_set
                        }

                        for (let i = 0 ; i < target_safety_set.length ; i++) {
                            if (target_safety_set[i] === item) {
                                // This is the bit we're looking for so just run the normal set value
                                tripped = IOGet(io_model, datapoint, item)
                                // console.log("Item = ", item, ", Tripped = ", tripped)
                                break
                            }

                            let vx = IOGet(io_model, datapoint, target_safety_set[i])
                            if (vx) {
                                // The preceding bit is tripped so this bit is not tripped
                                prior_safety = true
                                tripped = false
                                // console.log("Item ", item, " is not tripped as ", target_safety_set[i], " is tripped")
                                break
                            }
                        }
                    }

                    group.items.push({
                        io : item,
                        adc : target.adc,
                        label: target.io_id,
                        desc : target.desc,
                        value : raw,
                        trip: target.trip,
                        tripped: tripped,
                        bit: target.bit,
                        prior_safety : prior_safety,
                        safety: safety,
                        stxt : (raw === "1") ? target.hl : target.ll,
                        trc : target.trc,
                        sc : target.sc,
                        ps : target.ps || null
                    })
                }
            }

            result.groups.push(group)
        }
    }

    // console.log("IO Definition Mapped Result = ", result)
    return result
}
















