import moment from "moment";
import HttpCodes from "../library/HttpCodes";
import IOApiDefinition from "../api/ioApi";


const _ioStore = {
    timestamp : null,
    loading : false,
    io : null,
    timeline_io : null
}

class IODefinitionStoreClass {

    io = async (forceRefresh = false) => {
        if (_ioStore.loading) {
            return _ioStore.io ;
        }

        if (!forceRefresh && _ioStore.io) {
            return _ioStore.io ;
        }

        try {
            _ioStore.loading = true ;
            let result = await IOApiDefinition.getIODefinitions()
            if (result.status === HttpCodes.HttpOK) {
                _ioStore.io = result.data ;
                _ioStore.timestamp = moment.utc().format();

                // console.log("Loaded IO Definitions - ", _ioStore.io) ;
            }
        }
        catch(e) {
            console.error("Problem loading IO Definitions - ", e.message);
        }
        finally {
            _ioStore.loading = false ;
        }

        return _ioStore.io ;
    }

    timelines = async (forceRefresh = false) => {
        if (_ioStore.loading) {
            return _ioStore.timeline_io ;
        }

        if (!forceRefresh && _ioStore.timeline_io) {
            return _ioStore.timeline_io ;
        }

        try {
            _ioStore.loading = true ;
            let result = await IOApiDefinition.getIOTimelineDefinitions()
            if (result.status === HttpCodes.HttpOK) {
                _ioStore.timeline_io = result.data ;
                _ioStore.timestamp = moment.utc().format();

                console.log("Loaded Timeline IO Definitions - ", _ioStore.timeline_io) ;
            }
        }
        catch(e) {
            console.error("Problem loading IO Definitions - ", e.message);
        }
        finally {
            _ioStore.loading = false ;
        }

        return _ioStore.timeline_io ;
    }

    revisionIO = async (revisionNumber, forceRefresh = true) => {
        let io = await this.io(forceRefresh) ;
        if (!io) {
            return null ;
        }

        let target_rev = io.io_rev_map[revisionNumber] || io.io_rev_map["*"]
        switch (target_rev.io) {
            case "POST_0.3" : return io.fw_maps["v0_4"] ;
            case "POST_0.4" : return io.fw_maps["v0_4"] ;
            case "POST_0.5" : return io.fw_maps["v0_5"] ;
            case "POST_0.5.1" : return io.fw_maps["v0_5_1"] ;
            case "POST_0.5.2" : return io.fw_maps["v0_5_2"] ;
            default : return io.fw_maps["v0_5"] ;
        }
    }

    timelineIO = async (fw_version, forceRefresh = true) => {
        let timeline_io = await this.timelines(forceRefresh) ;
        if (!timeline_io) {
            return null ;
        }

        console.log("Timeline IO - ", fw_version, " for timeline ", timeline_io) ;
        let target_io = 3 ;
        switch (fw_version) {
            case "v0.4.04" : target_io = 4 ; break ;
            case "v0.5.2" : target_io = 5 ; break ;
            case "v0.5.102" : target_io = 5 ; break ;
            case "v0.3.04" : target_io = 3 ; break ;
            case "v0.3.06" : target_io = 3 ; break ;
            case "v0.3.05" : target_io = 3 ; break ;
            case "v0.4.00" : target_io = 4 ; break ;
            case "v0.5.3" : target_io = 5 ; break ;
            case "v1-04" : target_io = 3 ; break ;
            case "v1-41" : target_io = 3 ; break ;
            case "v0.4" : target_io = 4 ; break ;
            case "v0.4.01" : target_io = 4 ; break ;
            case "v0.5.0" : target_io = 5 ; break ;
            case "v0.5.00" : target_io = 5 ; break ;
            default :
                // NOTE, not perfect, but should work for now
                if (fw_version.startsWith("v0.5") || fw_version.startsWith("v0.6")) {
                    target_io = 5 ;
                }
                else {
                    target_io = 4 ;
                }
        }

        let iov = `V${target_io}`
        let result = timeline_io[iov] || null;
        console.log("Timeline IO - ", fw_version, " with key ", target_io, " for timeline ", result) ;
        return {
            fw_revision : target_io,
            fw_revision_key : iov,
            ...result
        };
    }

    ioDefinitionMap = async (forceRefresh = true) => {
       return await this.io(forceRefresh) ;
    }

    timelineIODefinitionMap = async (forceRefresh = true) => {
        return await this.timelines(forceRefresh) ;
    }
}

const IODefinitionStore = new IODefinitionStoreClass();
export default IODefinitionStore ;

