
export const drawDataPointLines = (canvas, ctx, ld, boundariesRef) => {
    let snapshot = ld.active_snapshot ;
    let st = snapshot.start_time;
    let et = snapshot.end_time;
    let bd = boundariesRef.current ;

    for (let idx = snapshot.start_index; idx <= snapshot.end_index; idx++) {
        let ts = ld.date_points[idx];
        let x = bd.left_pad + ((ts - st) / 1000) * bd.step_x_sec;
        // let x = ((ts - st) / 1000) * bd.step_x_sec;
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, 20);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'blue';
        ctx.stroke();

        if (idx === (ld.current_record - 1)) {
            // console.log("Drawing current record at ", ld, " snapshot=", snapshot, " x=", x, " idx=", idx, " ts=", ts, " left pad=", bd.left_pad, " step_x_sec=", bd.step_x_sec) ;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, boundariesRef.current.height);
            ctx.strokeStyle = 'darkgreen';
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.restore();
        }
    }
}
