export const findClosestBefore = (ld, x, boundariesRef) => {
    const snapshot = ld.active_snapshot ;
    const timestamps = ld.date_points;
    let startTime = snapshot.start_time.local();
    const seconds = (x - boundariesRef.current.left_pad) / boundariesRef.current.step_x_sec ;
    const newTime = startTime.clone().add(seconds, 'seconds');

    // console.log("main data=", ld, " snapshot=", snapshot, " seconds=", seconds, " newTime=", newTime);
    // console.log("Finding closest before", x, " start=", snapshot.start_index, " end=", snapshot.end_index, " time=", newTime);
    for (let i = snapshot.end_index; i >= snapshot.start_index; i--) {
        if (timestamps[i].isBefore(newTime)) {
            // console.log("Found closest before", i, " start=", snapshot.start_index, " end=", snapshot.end_index, " time=", timestamps[i], " time+1=", timestamps[i+1], " time-1=", timestamps[i-1]);
            return i + 1;
        }
    }

    return 0;
}

export const findClosestAfter = (ld, x, boundariesRef) => {
    const snapshot = ld.active_snapshot ;
    const timestamps = ld.date_points;
    let startTime = snapshot.start_time.local();
    const seconds = (x - boundariesRef.current.left_pad) / boundariesRef.current.step_x_sec ;
    const newTime = startTime.clone().add(seconds, 'seconds');

    for (let i = snapshot.start_index; i < snapshot.end_index; i++) {
        if (timestamps[i].isAfter(newTime)) {
            // return i - snapshot.start_index;
            return i ;
        }
    }

    return snapshot.end_index ;
}