
const normalize = (value, minv, maxv) => {
    return (value - minv) / (maxv - minv);
}
export const drawLineChartItem = (canvas, ctx, ld, yOffset, boundariesRef, chart_item) => {
    let snapshot = ld.active_snapshot ;
    const bd = boundariesRef.current ;

    ctx.save() ;
    if (!chart_item.show) {
        return yOffset ;
    }

    if (chart_item.shade_back) {
        ctx.save() ;
        ctx.fillStyle = chart_item.bg_color;
        ctx.fillRect(bd.left_pad, yOffset, bd.width - (bd.right_pad + bd.left_pad), bd.ln_chart_item_h);
        ctx.restore() ;
    }

    ctx.save() ;
    ctx.beginPath();
    ctx.moveTo(bd.left_pad, yOffset);
    ctx.lineTo(bd.width - bd.right_pad, yOffset);
    ctx.setLineDash([3, 5]);
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
    ctx.stroke();
    ctx.restore() ;

    const data = chart_item.ss_data;
    let d0 = data[0] ;
    let dv0 = normalize(d0, chart_item.low_bound, chart_item.high_bound) ;
    ctx.beginPath();
    ctx.moveTo(bd.left_pad, (yOffset + bd.ln_chart_item_h - bd.ln_chart_bottom_pad) - dv0 * bd.ln_chart_height);
    for (let j = 1; j < data.length; j++) {
        let tx = ld.date_points[j + snapshot.start_index] ;
        let dj = data[j] ;
        let dj0 = normalize(dj, chart_item.low_bound, chart_item.high_bound) ;
        let offsetX = bd.left_pad + tx.diff(snapshot.start_time, 'seconds') * bd.step_x_sec;
        ctx.lineTo(offsetX, (yOffset + bd.ln_chart_item_h - bd.ln_chart_bottom_pad) - dj0 * bd.ln_chart_height);
    }
    ctx.strokeStyle = chart_item.ln_color ?? 'black' ;
    ctx.stroke();

    if (chart_item.draw_centre_line) {
        ctx.save() ;
        let centre = yOffset + (bd.ln_chart_item_h / 2) ;
        ctx.beginPath();
        ctx.moveTo(bd.left_pad, centre);
        ctx.lineTo(bd.width - bd.right_pad, centre);
        ctx.setLineDash([10, 10]);
        ctx.strokeStyle = 'red';
        ctx.stroke();
        ctx.restore() ;
    }

    let ranges = chart_item.ranges || [] ;
    for (let range of ranges) {
        let low = normalize(range.low_bound, chart_item.low_bound, chart_item.high_bound) ;
        let high = normalize(range.high_bound, chart_item.low_bound, chart_item.high_bound) ;

        ctx.save();
        ctx.fillStyle = range.color ;
        ctx.fillRect(bd.left_pad, (yOffset + bd.ln_chart_item_h - bd.ln_chart_bottom_pad) - high * bd.ln_chart_height, bd.width - (bd.right_pad + bd.left_pad), (high - low) * bd.ln_chart_height);
        ctx.setLineDash([5, 5]);
        ctx.strokeStyle = 'rgb(175,171,171)';
        ctx.strokeRect(bd.left_pad, (yOffset + bd.ln_chart_item_h - bd.ln_chart_bottom_pad) - high * bd.ln_chart_height, bd.width - (bd.right_pad + bd.left_pad), (high - low) * bd.ln_chart_height);
        ctx.setLineDash([]);
        ctx.restore();

        ctx.save();
        ctx.fillStyle = 'black';
        ctx.font = '12px Arial';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${range.label} (${Math.round(range.low_bound / 1000)}V -> ${Math.round(range.high_bound / 1000)}V})`, bd.left_pad + 10, (yOffset + bd.ln_chart_item_h) - high * bd.ln_chart_height);
        ctx.restore();
    }

    ctx.restore();

    return yOffset + bd.ln_chart_item_h;
}
