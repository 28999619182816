import moment from "moment/moment";

export const drawAxisLabels = (canvas, ctx, ld, boundariesRef) => {
    let snapshot = ld.active_snapshot ;
    let st = snapshot.start_time;
    let et = snapshot.end_time;

    const duration = moment.duration(et.diff(st)) ;
    const totalDurationSecs = duration.asSeconds() ;
    let numIntervals = 20 ;
    let stepXSecond = boundariesRef.current.axis_width / totalDurationSecs ;
    let timeStep = totalDurationSecs / numIntervals ;
    const totalHours = duration.asHours();

    // Determine appropriate intervals for major and minor labels
    let majorInterval, minorInterval;
    if (totalHours <= 24) {
        majorInterval = 1; // 1 hour
        minorInterval = 0.5; // 30 minutes
    } else if (totalHours <= 72) {
        majorInterval = 6; // 6 hours
        minorInterval = 1; // 1 hour
    } else {
        majorInterval = 24; // 1 day
        minorInterval = 6; // 6 hours
    }

    const drawAxisLine = (canvas, ctx, x, style) => {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, boundariesRef.current.height - boundariesRef.current.xax_lbl_h);
        ctx.lineWidth = style.width;
        ctx.strokeStyle = style.color;
        ctx.stroke();
    }

    ctx.fillStyle = 'rgb(14,14,14)';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'middle';
    ctx.font = '12px Arial light'; // Improved font style

    ctx.beginPath();
    ctx.moveTo(boundariesRef.current.left_pad, boundariesRef.current.height - boundariesRef.current.xax_lbl_h);
    ctx.lineTo(boundariesRef.current.width - boundariesRef.current.right_pad, boundariesRef.current.height - boundariesRef.current.xax_lbl_h);
    ctx.stroke();

    // now we always need to label the start point
    ctx.save();
    ctx.translate(boundariesRef.current.left_pad, boundariesRef.current.xax_lbl_start_y + 10);
    ctx.rotate(-Math.PI / 2);
    ctx.fillText(st.format('MMM DD  HH:mm'), 0, 0);
    ctx.restore();

    drawAxisLine(canvas, ctx, boundariesRef.current.left_pad, {width: 1, color: 'darkgray'});

    for (let i = 0; i < numIntervals; i++) {
        const x = boundariesRef.current.left_pad + (i * stepXSecond * timeStep);
        const time = st.clone().add(i * timeStep, 'seconds').local();
        if (time.hour() % majorInterval === 0) {
            ctx.save();
            ctx.translate(x, boundariesRef.current.xax_lbl_start_y + 10);
            ctx.rotate(-Math.PI / 2);
            if (time.hour() === 0 || time.hour() === 12) {
                ctx.fillText(time.format('MMM DD  HH:mm'), 0, 0);
            }
            else {
                ctx.fillText(time.format('HH:mm'), 0, 0);
            }
            ctx.restore();
            drawAxisLine(canvas, ctx, x, {width: 0.5, color: 'darkgray'});
        } else if (time.hour() % minorInterval === 0) {
            ctx.save();
            ctx.translate(x, boundariesRef.current.xax_lbl_start_y + 10);
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(time.format('HH:mm'), 0, 0);
            ctx.restore();
            drawAxisLine(canvas, ctx, x, {width: 0.3, color: 'lightgray'});
        }
    }

    ctx.save();
    ctx.translate(boundariesRef.current.width - boundariesRef.current.right_pad, boundariesRef.current.xax_lbl_start_y + 10);
    ctx.rotate(-Math.PI / 2);
    ctx.fillText(et.format('MMM DD  HH:mm'), 0, 0);
    ctx.restore();

    drawAxisLine(canvas, ctx, boundariesRef.current.width - boundariesRef.current.right_pad, {width: 1, color: 'darkgray'});
}
